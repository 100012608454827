import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconBeer.module.css';

const IconBeer = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg
      className={classes}
      width="100"
      height="100"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill="#fdb924"
        d="M31.55,13.88V45.57a1.675,1.675,0,0,1-1.67,1.68H5.29a1.677,1.677,0,0,1-1.68-1.68V13.88Z"
      />
      <path fill="#ffd36c" d="M31.55,13.88V43.77H17.17a9.043,9.043,0,0,1-9.04-9.04V13.88Z" />
      <path
        fill="#fdb924"
        d="M10.165,41.762a.75.75,0,0,1-.75-.75V25.677a.75.75,0,0,1,1.5,0V41.012A.75.75,0,0,1,10.165,41.762Z"
      />
      <path
        fill="#fdb924"
        d="M17.523,41.762a.75.75,0,0,1-.75-.75V24.1a.75.75,0,0,1,1.5,0V41.012A.75.75,0,0,1,17.523,41.762Z"
      />
      <path
        fill="#fdb924"
        d="M24.881,41.762a.75.75,0,0,1-.75-.75V36.664a.75.75,0,0,1,1.5,0v4.348A.75.75,0,0,1,24.881,41.762Z"
      />
      <path
        fill="#fdb924"
        d="M24.881,35.1a.75.75,0,0,1-.75-.75V24.1a.75.75,0,0,1,1.5,0v10.25A.75.75,0,0,1,24.881,35.1Z"
      />
      <path
        fill="#ffd36c"
        d="M37.787,19.932H31.555v3.8h5.6a3.437,3.437,0,0,1,3.437,3.437v6.611a3.436,3.436,0,0,1-3.437,3.437h-5.6v3.8h6.232a6.6,6.6,0,0,0,6.6-6.6V26.535A6.6,6.6,0,0,0,37.787,19.932Z"
      />
      <path
        fill="#0099e2"
        d="M31.4,12.69a5.259,5.259,0,0,1-3.93,3.9,4.56,4.56,0,0,1-1.5.13l-.03.65a1.94,1.94,0,1,1-3.88,0V14.71a5.15,5.15,0,0,1-2.47.71,8.134,8.134,0,0,1-3.89-.55c-1.83-.83-2-1.11-2-1.11a5.6,5.6,0,0,1-1.4,1.77,3.563,3.563,0,0,1-2.13,1.06l-.01,4.91a1.94,1.94,0,0,1-3.88,0V16.03a5.351,5.351,0,0,1-2.67-4.37,5.023,5.023,0,0,1,.2-1.6A5.75,5.75,0,0,1,6.98,6.52a4.718,4.718,0,0,1,3.71-.12,4.45,4.45,0,0,1,4.92-1.53A5.453,5.453,0,0,1,26.35,6.09a3.982,3.982,0,0,1,2.74.83,5.743,5.743,0,0,1,2.33,3.46A5.3,5.3,0,0,1,31.4,12.69Z"
      />
      <path
        fill="#007db8"
        d="M31.4,12.69a5.259,5.259,0,0,1-3.93,3.9,4.56,4.56,0,0,1-1.5.13l-.03.65a1.94,1.94,0,1,1-3.88,0V14.71a5.15,5.15,0,0,1-2.47.71,8.134,8.134,0,0,1-3.89-.55c-1.83-.83-2-1.11-2-1.11a5.6,5.6,0,0,1-1.4,1.77,3.563,3.563,0,0,1-2.13,1.06l-.01,4.91a1.94,1.94,0,0,1-3.88,0V16.03a5.351,5.351,0,0,1-2.67-4.37,5.023,5.023,0,0,1,.2-1.6c.49,1.04,1.87,3.46,4.25,3.85,3.47.56,5.64-2.85,5.64-2.85a8.931,8.931,0,0,0,4.57,1.69,9.555,9.555,0,0,0,4.57-1.34s1.1,2.39,3.13,2.62a5.64,5.64,0,0,0,5.45-3.65A5.3,5.3,0,0,1,31.4,12.69Z"
      />
      <path
        fill="#fdb10d"
        d="M22.06,14.71a5.15,5.15,0,0,1-2.47.71,8.134,8.134,0,0,1-3.89-.55c-1.83-.83-2-1.11-2-1.11a5.6,5.6,0,0,1-1.4,1.77,3.563,3.563,0,0,1-2.13,1.06l-.01,4.91a1.94,1.94,0,0,1-3.88,0v1.134a1.942,1.942,0,0,0,1.94,1.94,1.929,1.929,0,0,0,1.94-1.94l.01-4.91a3.563,3.563,0,0,0,2.13-1.06,5.6,5.6,0,0,0,1.4-1.77s.17.28,2,1.11a8.134,8.134,0,0,0,3.89.55,5.15,5.15,0,0,0,2.47-.71Z"
      />
      <path
        fill="#fdb10d"
        d="M6.28,16.03v1.13a5.318,5.318,0,0,1-1.76-1.64,5.4,5.4,0,0,1-.74-1.64h.44a5.235,5.235,0,0,0,.3.51A5.318,5.318,0,0,0,6.28,16.03Z"
      />
      <path
        fill="#fdb10d"
        d="M31.38,13.88a5.251,5.251,0,0,1-3.91,3.84,4.56,4.56,0,0,1-1.5.13l-.03.65a1.94,1.94,0,0,1-3.88,0V17.37a1.94,1.94,0,0,0,3.88,0l.03-.65a4.56,4.56,0,0,0,1.5-.13,5.293,5.293,0,0,0,3.49-2.71Z"
      />
      <rect fill="#fdb924" height="3.797" width="2.237" x="31.555" y="19.932" />
      <rect fill="#fdb924" height="3.797" width="2.237" x="31.555" y="37.214" />
    </svg>
  );
};

IconBeer.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

IconBeer.propTypes = { rootClassName: string, className: string };

export default IconBeer;
