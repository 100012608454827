/*
 * Marketplace specific configuration.
 *
 * Every filter needs to have following keys:
 * - id:     Unique id of the filter.
 * - label:  The default label of the filter.
 * - type:   String that represents one of the existing filter components:
 *           BookingDateRangeFilter, KeywordFilter, PriceFilter,
 *           SelectSingleFilter, SelectMultipleFilter.
 * - group:  Is this 'primary' or 'secondary' filter?
 *           Primary filters are visible on desktop layout by default.
 *           Secondary filters are behind "More filters" button.
 *           Read more from src/containers/SearchPage/README.md
 * - queryParamNames: Describes parameters to be used with queries
 *                    (e.g. 'price' or 'pub_amenities'). Most of these are
 *                    the same between webapp URLs and API query params.
 *                    You can't change 'dates', 'price', or 'keywords'
 *                    since those filters are fixed to a specific attribute.
 * - config: Extra configuration that the filter component needs.
 *
 * Note 1: Labels could be tied to translation file
 *         by importing FormattedMessage:
 *         <FormattedMessage id="some.translation.key.here" />
 *
 * Note 2: If you need to add new custom filter components,
 *         you need to take those into use in:
 *         src/containers/SearchPage/FilterComponent.js
 *
 * Note 3: If you just want to create more enum filters
 *         (i.e. SelectSingleFilter, SelectMultipleFilter),
 *         you can just add more configurations with those filter types
 *         and tie them with correct extended data key
 *         (i.e. pub_<key> or meta_<key>).
 */

export const filters = [
  {
    id: 'runCategories',
    label: 'Categories',
    type: 'SelectMultipleFilter',
    group: 'primary',
    queryParamNames: ['pub_runCategories'],
    config: {
      // Optional modes: 'has_all', 'has_any'
      // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
      searchMode: 'has_any',
      // "key" is the option you see in Flex Console.
      // "label" is set here for this web app's UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'sightseeing', label: 'Sightseeing' },
        { key: 'coaching', label: 'Coaching' },
        { key: 'trail', label: 'Trail Run' },
        { key: 'champion', label: 'Run with a Champion' },
        { key: 'friendship', label: 'Friendship' },
        { key: 'nocategory', label: 'No category' },
      ],
    },
  },
  {
    id: 'keyword',
    label: 'Keyword',
    type: 'KeywordFilter',
    group: 'primary',
    // Note: KeywordFilter is fixed filter,
    // you can't change "queryParamNames: ['keywords'],"
    queryParamNames: ['keywords'],
    // NOTE: If you are ordering search results by distance
    // the keyword search can't be used at the same time.
    // You can turn on/off ordering by distance from config.js file.
    config: {},
  },
  {
    id: 'dates-length',
    label: 'Dates',
    type: 'BookingDateRangeLengthFilter',
    group: 'primary',
    // Note: BookingDateRangeFilter is fixed filter,
    // you can't change "queryParamNames: ['dates'],"
    queryParamNames: ['dates', 'minDuration'],
    config: {
      // A global time zone to use in availability searches. As listings
      // can be in various time zones, we must decide what time zone we
      // use in search when looking for available listings within a
      // certain time interval.
      //
      // If you have all/most listings in a certain time zone, change this
      // config value to that.
      //
      // See: https://en.wikipedia.org/wiki/List_of_tz_database_time_zones
      searchTimeZone: 'Etc/UTC',

      // Options for the minimum duration of the booking
      options: [
        { key: '0', label: 'Any length' },
        { key: '60', label: '1 hour', shortLabel: '1h' },
        { key: '120', label: '2 hours', shortLabel: '2h' },
      ],
    },
  },
  {
    id: 'price',
    label: 'Price',
    type: 'PriceFilter',
    group: 'primary',
    // Note: PriceFilter is fixed filter,
    // you can't change "queryParamNames: ['price'],"
    queryParamNames: ['price'],
    // Price filter configuration
    // Note: unlike most prices this is not handled in subunits
    config: {
      min: 0,
      max: 500,
      step: 5,
    },
  },
  {
    id: 'runDuration',
    label: 'Duration',
    type: 'SelectMultipleFilter',
    group: 'primary',
    queryParamNames: ['pub_runDuration'],
    config: {
      // Optional modes: 'has_all', 'has_any'
      // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
      // searchMode: 'has_any',
      // "key" is the option you see in Flex Console.
      // "label" is set here for this web app's UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'tailormadeDur', label: 'tailormade duration', duration: 1 },
        { key: '30minorless', label: '30 min or less', duration: 1 },
        { key: 'about45min', label: '45 min', duration: 1 },
        { key: 'about1h', label: '1 h', duration: 1 },
        { key: 'about1_5h', label: '1.5 h', duration: 2 },
        { key: 'about2h', label: '2 h', duration: 2 },
        { key: 'about2_5h', label: '2.5 h', duration: 3 },
        { key: 'about3h', label: '3 h', duration: 3 },
        { key: 'about3_5h', label: '3.5 h', duration: 4 },
        { key: 'about4h', label: '4 h', duration: 4 },
        { key: 'about4_5h', label: '4.5 h', duration: 5 },
        { key: 'about5h', label: '5 h', duration: 5 },
        { key: 'about5_5h', label: '5.5 h', duration: 6 },
        { key: '6hormore', label: '6 h or more', duration: 6 },
        { key: 'morethan1day', label: 'more than 1 day', duration: 1 },
      ],
    },
  },
  {
    id: 'runDistance',
    label: 'Distance',
    type: 'SelectMultipleFilter',
    group: 'primary',
    queryParamNames: ['pub_runDistance'],
    config: {
      // Optional modes: 'has_all', 'has_any'
      // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
      // searchMode: 'has_any',
      // "key" is the option you see in Flex Console.
      // "label" is set here for this web app's UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'tailormadeDist', label: 'tailormade distance' },
        { key: '3kmorless', label: '3 km or less' },
        { key: '4or5km', label: '4 or 5 km' },
        { key: '6or7km', label: '6 or 7 km' },
        { key: '8or9km', label: '8 or 9 km' },
        { key: '10or11km', label: '10 or 11 km' },
        { key: '12or13km', label: '12 or 13 km' },
        { key: '14or15km', label: '14 or 15 km' },
        { key: '16or17km', label: '16 or 17 km' },
        { key: '18or19km', label: '18 or 19 km' },
        { key: 'about20to29km', label: '20 to 29 km' },
        { key: 'halfmarathon', label: 'Half marathon (21.0975 km)' },
        { key: 'about30to39km', label: '30 to 39 km' },
        { key: '40kmormore', label: '40 km or more' },
        { key: 'marathon', label: 'Marathon (42.195 km)' },
      ],
    },
  },
  {
    id: 'runMaxPax',
    label: 'Maximum number of guest runners',
    type: 'SelectMultipleFilter',
    group: 'secondary',
    queryParamNames: ['pub_runMaxPax'],
    config: {
      // Optional modes: 'has_all', 'has_any'
      // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
      // searchMode: 'has_any',
      // "key" is the option you see in Flex Console.
      // "label" is set here for this web app's UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: '1', label: '1' },
        { key: '2', label: '2' },
        { key: '3', label: '3' },
        { key: '4', label: '4' },
        { key: '5', label: '5' },
        { key: '6', label: '6' },
        { key: '7', label: '7' },
        { key: '8', label: '8' },
        { key: '9', label: '9' },
        { key: '10', label: '10' },
      ],
    },
  },
  {
    id: 'runLanguages',
    label: 'Languages',
    type: 'SelectMultipleFilter',
    group: 'secondary',
    queryParamNames: ['pub_runLanguages'],
    config: {
      // Optional modes: 'has_all', 'has_any'
      // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
      searchMode: 'has_any',
      // "key" is the option you see in Flex Console.
      // "label" is set here for this web app's UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'english', label: 'English' },
        { key: 'deutsch', label: 'Deutsch' },
        { key: 'espanol', label: 'español' },
        { key: 'francais', label: 'français' },
        { key: 'italiano', label: 'italiano' },
        { key: 'nederlands', label: 'Nederlands' },
        { key: 'portugues', label: 'português' },
        { key: 'japanese', label: '日本語' },
        { key: 'chinese', label: '中文' },
      ],
    },
  },
  //  {
  //    id: 'certificate',
  //    label: 'Certificate',
  //    type: 'SelectSingleFilter',
  //    group: 'secondary',
  //    queryParamNames: ['pub_certificate'],
  //    config: {
  // "key" is the option you see in Flex Console.
  // "label" is set here for the UI only.
  // Note: label is not added through the translation files
  // to make filter customizations a bit easier.
  //      options: [
  //        { key: 'none', label: 'None', hideFromFilters: true, hideFromListingInfo: true },
  //        { key: '200h', label: 'Registered yoga teacher 200h' },
  //        { key: '500h', label: 'Registered yoga teacher 500h' },
  //      ],
  //    },
  //  },
];

export const sortConfig = {
  // Enable/disable the sorting control in the SearchPage
  active: true,

  // Note: queryParamName 'sort' is fixed,
  // you can't change it since Flex API expects it to be named as 'sort'
  queryParamName: 'sort',

  // Internal key for the relevance option, see notes below.
  relevanceKey: 'relevance',

  // Keyword filter is sorting the results already by relevance.
  // If keyword filter is active, we need to disable sorting.
  conflictingFilters: ['keyword'],

  options: [
    { key: 'createdAt', label: 'Newest' },
    { key: '-createdAt', label: 'Oldest' },
    { key: '-price', label: 'Lowest price' },
    { key: 'price', label: 'Highest price' },

    // The relevance is only used for keyword search, but the
    // parameter isn't sent to the Marketplace API. The key is purely
    // for handling the internal state of the sorting dropdown.
    { key: 'relevance', label: 'Relevance', longLabel: 'Relevance (Keyword search)' },
  ],
};
