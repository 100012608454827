import React from 'react';
import PropTypes from 'prop-types';

import css from './IconKeys.module.css';

const IconKeys = props => {
  const { className } = props;
  return (
    <svg
      className={className}
      width="64px"
      height="64px"
      viewBox="6 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        height="29.013"
        rx="1.923"
        ry="1.923"
        fill="#0099e2"
        width="33.366"
        x="7.317"
        y="18.237"
      />
      <path
        d="M36.421,21.4H11.579a1.1,1.1,0,0,0-1.1,1.1V42.988a1.1,1.1,0,0,0,1.1,1.1H36.421a1.1,1.1,0,0,0,1.1-1.1V22.5A1.1,1.1,0,0,0,36.421,21.4Z"
        fill="#33beff"
      />
      <path
        d="M24,.75A14.523,14.523,0,0,0,9.493,15.256v2.981h2.992l.021-2.981A11.489,11.489,0,0,1,32.557,7.591l1.383,1.9a11.412,11.412,0,0,1,1.554,5.764v2.981h3.013V15.256A14.523,14.523,0,0,0,24,.75Z"
        fill="#33beff"
      />
      <path
        d="M28.223,29.666A4.223,4.223,0,1,0,22.6,33.633v5.919a1.4,1.4,0,0,0,1.4,1.4h0a1.4,1.4,0,0,0,1.4-1.4V33.633A4.21,4.21,0,0,0,28.223,29.666Z"
        fill="#484a50"
      />
      <rect height="1.44" fill="#007db8" width="2.992" x="9.493" y="16.797" />
      <rect height="1.44" fill="#007db8" width="2.992" x="35.515" y="16.797" />
    </svg>
  );
};

IconKeys.defaultProps = { className: null };

const { string } = PropTypes;

IconKeys.propTypes = {
  className: string,
};

export default IconKeys;
