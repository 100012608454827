import React from 'react';
import { bool, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { OwnListingLink, IconBook, IconRun, IconReview } from '../../components';
import { Controller, Scene } from 'react-scrollmagic';

import css from './SectionHowItWorks.module.css';

const SectionHowItWorks = props => {
  const { rootClassName, className } = props;

  const classes = classNames(rootClassName || css.root, className);
  return (
    <div className={classes}>
      <div className={css.title}>
        <FormattedMessage id="SectionHowItWorks.titleLineOne" />
        <br />
        <FormattedMessage id="SectionHowItWorks.titleLineTwo" />
      </div>
      <div className={css.steps}>
        <div className={css.step}>
          <Controller>
            <Scene duration={0} classToggle={css.iconVisible} offset={-200}>
              <div className={css.iconInvisible}>
                <IconBook />
              </div>
            </Scene>
          </Controller>
          <h2 className={css.stepTitle}>
            <FormattedMessage id="SectionHowItWorks.subtitle1" />
          </h2>
          <p>
            <FormattedMessage id="SectionHowItWorks.subtext1" />
          </p>
        </div>
        <div className={css.step}>
          <Controller>
            <Scene duration={0} classToggle={css.iconVisible} offset={-200}>
              <div className={css.iconInvisible}>
                <IconRun />
              </div>
            </Scene>
          </Controller>
          <h2 className={css.stepTitle}>
            <FormattedMessage id="SectionHowItWorks.subtitle2" />
          </h2>
          <p>
            <FormattedMessage id="SectionHowItWorks.subtext2" />
          </p>
        </div>
        <div className={css.step}>
          <Controller>
            <Scene duration={0} classToggle={css.iconVisible} offset={-200}>
              <div className={css.iconInvisible}>
                <IconReview />
              </div>
            </Scene>
          </Controller>
          <h2 className={css.stepTitle}>
            <FormattedMessage id="SectionHowItWorks.subtitle3" />
          </h2>
          <p>
            <FormattedMessage id="SectionHowItWorks.subtext3" />
          </p>
        </div>
      </div>
    </div>
  );
};

SectionHowItWorks.defaultProps = {
  rootClassName: null,
  className: null,
};

SectionHowItWorks.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionHowItWorks;
