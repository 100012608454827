import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconReview.module.css';

const IconReview = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg
      className={classes}
      width="100"
      height="100"
      viewBox="0 0 64 64"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M42.5,28A2.5,2.5,0,0,0,40,30.5v-1a2.5,2.5,0,0,0-5,0v-1a2.5,2.5,0,0,0-5,0v-11a2.5,2.5,0,0,0-5,0V33.774a4.433,4.433,0,0,0-2.421-3.95l-.888-.453A1.851,1.851,0,0,0,19,31.02v12.8L24,51v5H41V51l4-6V30.5A2.5,2.5,0,0,0,42.5,28Z"
        fill="#fcd3c4"
      />
      <path
        d="M26,51.32V51l-5-7.183V31.02a1.83,1.83,0,0,1,.863-1.561l-.172-.088A1.851,1.851,0,0,0,19,31.02v12.8L24,51v5H41V53H27.68A1.68,1.68,0,0,1,26,51.32Z"
        fill="#fbc5b1"
      />
      <path
        d="M26.776,15.106A2.564,2.564,0,0,0,25,17.592V33.774a4.434,4.434,0,0,0-1-2.8V38.5A1.5,1.5,0,0,0,25.5,40h0A1.5,1.5,0,0,0,27,38.5v-21a2.5,2.5,0,0,1,1.5-2.289A2.5,2.5,0,0,0,26.776,15.106Z"
        fill="#fbc5b1"
      />
      <rect height="8" fill="#007db8" width="21.996" x="21.002" y="55" />
      <path d="M26.819,60H43V55H24v2.176A2.824,2.824,0,0,0,26.819,60Z" fill="#0099e2" />
      <path
        d="M9.879,4.764l-1.036.785a2.55,2.55,0,0,0-.865,2.776L8.372,9.6a.3.3,0,0,1-.451.342L6.878,9.15a2.3,2.3,0,0,0-2.792,0L3.055,9.93a.3.3,0,0,1-.45-.345L3,8.336a2.55,2.55,0,0,0-.858-2.795L1.121,4.764a.307.307,0,0,1,.171-.552H2.54A2.389,2.389,0,0,0,4.8,2.5L5.205,1.21a.288.288,0,0,1,.554,0l.4,1.289A2.394,2.394,0,0,0,8.427,4.212H9.708A.307.307,0,0,1,9.879,4.764Z"
        fill="#ffd36c"
      />
      <path
        d="M4.54,4a2.325,2.325,0,0,0,1.873-.948A2.637,2.637,0,0,1,6.164,2.5l-.4-1.289a.288.288,0,0,0-.554,0L4.8,2.5A2.463,2.463,0,0,1,3.476,4.009Z"
        fill="#fdb924"
      />
      <path
        d="M6.261,8.814a2.382,2.382,0,0,1,.617.336l1.043.79A.3.3,0,0,0,8.372,9.6L8.045,8.542A2.292,2.292,0,0,0,6.261,8.814Z"
        fill="#fdb924"
      />
      <path
        d="M5,8.124a2.549,2.549,0,0,0-.858-2.8L3.121,4.551a.292.292,0,0,1-.049-.4,2.4,2.4,0,0,1-.532.062H1.292a.307.307,0,0,0-.171.552l1.024.777A2.55,2.55,0,0,1,3,8.336l-.4,1.249a.3.3,0,0,0,.45.345l1.031-.781a2.323,2.323,0,0,1,.706-.363Z"
        fill="#fdb924"
      />
      <path
        d="M23.129,4.764l-1.036.785a2.55,2.55,0,0,0-.865,2.776L21.622,9.6a.3.3,0,0,1-.451.342l-1.043-.79a2.3,2.3,0,0,0-2.792,0l-1.031.781a.3.3,0,0,1-.45-.345l.4-1.249A2.55,2.55,0,0,0,15.4,5.541l-1.024-.777a.307.307,0,0,1,.171-.552H15.79A2.389,2.389,0,0,0,18.048,2.5l.407-1.293a.288.288,0,0,1,.554,0L19.414,2.5a2.394,2.394,0,0,0,2.263,1.713h1.281A.307.307,0,0,1,23.129,4.764Z"
        fill="#ffd36c"
      />
      <path
        d="M17.79,4a2.338,2.338,0,0,0,1.873-.962,2.546,2.546,0,0,1-.249-.539L19.009,1.21a.288.288,0,0,0-.554,0L18.048,2.5A2.439,2.439,0,0,1,16.735,4Z"
        fill="#fdb924"
      />
      <path
        d="M19.518,8.821a2.333,2.333,0,0,1,.61.329l1.043.79a.3.3,0,0,0,.451-.342L21.3,8.542A2.282,2.282,0,0,0,19.518,8.821Z"
        fill="#fdb924"
      />
      <path
        d="M18.253,8.124a2.549,2.549,0,0,0-.858-2.8l-1.024-.778a.3.3,0,0,1-.063-.406,2.228,2.228,0,0,1-.518.067H14.542a.307.307,0,0,0-.171.552l1.024.777a2.55,2.55,0,0,1,.858,2.8l-.4,1.249a.3.3,0,0,0,.45.345l1.031-.781a2.323,2.323,0,0,1,.706-.363Z"
        fill="#fdb924"
      />
      <path
        d="M36.379,4.764l-1.036.785a2.548,2.548,0,0,0-.865,2.776L34.872,9.6a.3.3,0,0,1-.451.342l-1.044-.79a2.3,2.3,0,0,0-2.791,0l-1.031.781a.3.3,0,0,1-.45-.345l.4-1.249a2.55,2.55,0,0,0-.858-2.795l-1.024-.777a.307.307,0,0,1,.171-.552H29.04A2.389,2.389,0,0,0,31.3,2.5L31.7,1.21a.288.288,0,0,1,.555,0L32.664,2.5a2.394,2.394,0,0,0,2.263,1.713h1.281A.307.307,0,0,1,36.379,4.764Z"
        fill="#ffd36c"
      />
      <path
        d="M31.04,4a2.338,2.338,0,0,0,1.873-.962,2.546,2.546,0,0,1-.249-.539L32.259,1.21a.288.288,0,0,0-.554,0L31.3,2.5A2.44,2.44,0,0,1,29.984,4Z"
        fill="#fdb924"
      />
      <path
        d="M32.768,8.821a2.312,2.312,0,0,1,.609.329l1.044.79a.3.3,0,0,0,.451-.342l-.327-1.056A2.282,2.282,0,0,0,32.768,8.821Z"
        fill="#fdb924"
      />
      <path
        d="M31.5,8.124a2.549,2.549,0,0,0-.858-2.8l-1.024-.778a.3.3,0,0,1-.063-.406,2.228,2.228,0,0,1-.518.067H27.792a.307.307,0,0,0-.171.552l1.024.777a2.55,2.55,0,0,1,.858,2.8l-.4,1.249a.3.3,0,0,0,.45.345l1.03-.781a2.339,2.339,0,0,1,.707-.363Z"
        fill="#fdb924"
      />
      <path
        d="M49.629,4.764l-1.036.785a2.548,2.548,0,0,0-.865,2.776L48.122,9.6a.3.3,0,0,1-.451.342l-1.044-.79a2.3,2.3,0,0,0-2.792,0l-1.03.781a.3.3,0,0,1-.45-.345l.4-1.249A2.55,2.55,0,0,0,41.9,5.541l-1.024-.777a.307.307,0,0,1,.171-.552H42.29A2.389,2.389,0,0,0,44.548,2.5l.406-1.293a.288.288,0,0,1,.555,0L45.914,2.5a2.394,2.394,0,0,0,2.263,1.713h1.281A.307.307,0,0,1,49.629,4.764Z"
        fill="#ffd36c"
      />
      <path
        d="M44.29,4a2.338,2.338,0,0,0,1.873-.962,2.546,2.546,0,0,1-.249-.539L45.509,1.21a.288.288,0,0,0-.555,0L44.548,2.5A2.44,2.44,0,0,1,43.234,4Z"
        fill="#fdb924"
      />
      <path
        d="M46.018,8.821a2.312,2.312,0,0,1,.609.329l1.044.79a.3.3,0,0,0,.451-.342L47.8,8.542A2.282,2.282,0,0,0,46.018,8.821Z"
        fill="#fdb924"
      />
      <path
        d="M44.753,8.124a2.549,2.549,0,0,0-.858-2.8l-1.024-.778a.3.3,0,0,1-.063-.406,2.228,2.228,0,0,1-.518.067H41.042a.307.307,0,0,0-.171.552l1.024.777a2.55,2.55,0,0,1,.858,2.8l-.4,1.249a.3.3,0,0,0,.45.345l1.03-.781a2.339,2.339,0,0,1,.707-.363Z"
        fill="#fdb924"
      />
      <path
        d="M62.879,4.764l-1.036.785a2.548,2.548,0,0,0-.865,2.776L61.372,9.6a.3.3,0,0,1-.451.342l-1.044-.79a2.3,2.3,0,0,0-2.792,0l-1.03.781a.3.3,0,0,1-.45-.345L56,8.336a2.55,2.55,0,0,0-.858-2.795l-1.024-.777a.307.307,0,0,1,.171-.552H55.54A2.389,2.389,0,0,0,57.8,2.5L58.2,1.21a.288.288,0,0,1,.555,0L59.164,2.5a2.394,2.394,0,0,0,2.263,1.713h1.281A.307.307,0,0,1,62.879,4.764Z"
        fill="#ffd36c"
      />
      <path
        d="M57.54,4a2.338,2.338,0,0,0,1.873-.962,2.546,2.546,0,0,1-.249-.539L58.759,1.21a.288.288,0,0,0-.555,0L57.8,2.5A2.44,2.44,0,0,1,56.484,4Z"
        fill="#fdb924"
      />
      <path
        d="M59.268,8.821a2.312,2.312,0,0,1,.609.329l1.044.79a.3.3,0,0,0,.451-.342l-.327-1.056A2.282,2.282,0,0,0,59.268,8.821Z"
        fill="#fdb924"
      />
      <path
        d="M58,8.124a2.549,2.549,0,0,0-.858-2.8l-1.024-.778a.3.3,0,0,1-.063-.406,2.228,2.228,0,0,1-.518.067H54.292a.307.307,0,0,0-.171.552l1.024.777A2.55,2.55,0,0,1,56,8.336l-.4,1.249a.3.3,0,0,0,.45.345l1.03-.781a2.339,2.339,0,0,1,.707-.363Z"
        fill="#fdb924"
      />
    </svg>
  );
};

IconReview.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

IconReview.propTypes = { rootClassName: string, className: string };

export default IconReview;
