import React from 'react';
import { array, shape, string } from 'prop-types';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';

import css from './ListingPage.module.css';

const SectionrunDistance = props => {
  const { publicData, options } = props;

  const runDistance = publicData.runDistance;
  const runDistanceOption = options.find(option => option.key === runDistance);

  return runDistanceOption ? (
    <div className={css.sectionrunDistance}>
      <h2 className={css.runDistanceTitle}>
        <FormattedMessage id="ListingPage.runDistanceTitle" />
      </h2>
      <p>{runDistanceOption.label}</p>
    </div>
  ) : null;
};

SectionrunDistance.propTypes = {
  options: array.isRequired,
  publicData: shape({
    runDistance: string,
  }).isRequired,
};

export default SectionrunDistance;
