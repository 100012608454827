import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './PrivacyPolicy.module.css';

const PrivacyPolicy = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  // prettier-ignore
  return (
    <div className={classes}>
      <p className={css.lastUpdated}>
        Last updated: May 4<sup>th</sup>, 2021
      </p>
      <p>
        This <strong>Privacy Policy</strong> provides information on what kind of personal data we process, why we process it, how we process it and where we proess it - in particular in connection with our <strong>www.shareyourrun.com</strong> Website and any of our other services. With this Privacy Policy, we also provide information about the rights of persons whose data we process.
      </p>
      <p>
        Special, supplementary or further Privacy Policies as well as other legal documents - such as General Terms and Conditions (GTC), Terms of Use or Conditions of Participation - may apply to individual or additional offers and services.
      </p>
      <p>
        Our offer is subject to Swiss data protection law as well as any applicable foreign data protection law such as, in particular, that of the European Union (EU) with the General Data Protection Regulation (GDPR). The European Commission <a href="https://eur-lex.europa.eu/legal-content/EN/TXT/?uri=CELEX:32000D0518" target="_blank" rel="noopener noreferrer">recognises</a> that Swiss data protection law ensures an adequate level of data protection.
      </p>
      <h2 id="contact addresses">1. Contact addresses</h2>
      <p>
        Responsibility for the processing of personal data:
      </p>
      <p>
        <strong id="responsible">SHARE YOUR RUN GmbH</strong><br />
        Albisriederstrasse 334<br />
        8047 Zürich<br />
        Switzerland
      </p>
      <p>
        <a href="mailto:hello@shareyourrun.com">hello@shareyourrun.com</a>
      </p>
      <p>
        We point out if there are other responsible parties for the processing of personal data in individual cases.
      </p>
      <h2 id="processing">2. Processing of personal data</h2>
      <h3 id="terms">2.1. Terms</h3>
      <p>
        <strong>Personal data</strong> is any information relating to an identified or identifiable person. A <strong>data subject</strong> is a person about whom personal data are processed. <strong>Processing</strong> includes <em>any</em> handling of personal data, <em>independent</em> of the means and procedures used, in particular the retention, disclosure, acquisition, collection, deletion, storage, modification, destruction and use of personal data.
      </p>
      <p>
        The <strong>European Economic Area (EEA)</strong> comprises the European Union (EU) and the Principality of Liechtenstein, Iceland and Norway.
      </p>
      <h3 id="legal basis">2.2. Legal basis</h3>
      <p>
        We process personal data in accordance with Swiss data protection law, such as the <a href="https://fedlex.data.admin.ch/eli/cc/1993/1945_1945_1945" target="_blank" id="40249" rel="noopener noreferrer">Federal Act on Data Protection</a> (FADP) and the <a href="https://fedlex.data.admin.ch/eli/cc/1993/1962_1962_1962" target="_blank" rel="noopener noreferrer">Ordinance to the Federal Act on Data Protection</a> (DPO).
      </p>
      <p>
        <span id="dsgvo">We process - if and to the extent that the General Data Protection Regulation (GDPR) is applicable - personal data in accordance with <em>at least</em> one of the following legal bases:</span>
      </p>
      <p>
        <div className={css.list}>
          <a href="https://eur-lex.europa.eu/legal-content/EN/TXT/HTML/?uri=CELEX:32016R0679&from=DE#d1e1888-1-1" rel="nofollow noopener noreferrer" target="_blank">Art. 6 para. 1 lit. b DSGVO</a> for the necessary processing of personal data to fulfill a contract with the data subject as well as for the implementation of pre-contractual measures.
        </div>
        <div className={css.list}>
          Art. 6 para. 1 lit. f DSGVO for the necessary processing of personal data to protect the legitimate interests of us or of third parties, unless the fundamental freedoms and rights and interests of the data subject prevail. Legitimate interests are, in particular: our interest in being able to provide our offer permanently, in a user-friendly, secure and reliable manner, as well as to be able to advertise for it as required; information security as well as protection against misuse and unauthorised use; the enforcement of our own legal claims and compliance with Swiss law.
        </div>
        <div className={css.list}>
          Art. 6 para. 1 lit. c DSGVO for the necessary processing of personal data to comply with a legal obligation to which we are subject under any applicable law of Member States in the European Economic Area (EEA).
        </div>
        <div className={css.list}>
          Art. 6 para. 1 lit. e DSGVO for the necessary processing of personal data for the fulfillment of a task carried out in the public interest.
        </div>
        <div className={css.list}>
          Art. 6 para. 1 lit. a DSGVO for the processing of personal data with the consent of the data subject.
        </div>
        <div className={css.list}>
          Art. 6 para. 1 lit. d DSGVO for the necessary processing of personal data to protect vital interests of the data subject or another natural person.
        </div>
      </p>
      <h3 id="type-scope-purpose">2.3. Type, scope and purpose</h3>
      <p>
        We process the personal data that is <strong>required</strong> in order to provide our service in a durable, user-friendly, secure and reliable manner. Such personal data can fall into the categories of inventory and contact data, browser and device data, content data, meta data and usage data, location data, sales data, contract data and payment data.
      </p>
      <p>
        We process personal data for the <strong>period</strong> required for the relevant purpose(s) or as required by law. Personal data that are no longer required to be processed are anonymised or deleted. Persons whose data we process have <em>as a matter of principle</em> a right to deletion.
      </p>
      <p>
        We only process personal data <em>in principle</em> with the consent of the data subject, unless the processing is permitted for other legal reasons, for example for the fulfillment of a contract with the data subject and for corresponding pre-contractual measures, in order to safeguard our overriding legitimate interests, because the processing is evident from the circumstances or after prior information.
      </p>
      <p>
        In this context, we process in particular information that a data subject provides to us <em>voluntarily and himself*herself</em> when contacting us - for example, by letter, email, contact form, social media or telephone - or when registering for a user account. We may store such information, for example, in an address book, in a customer relationship management system (CRM system) or with comparable tools. If you transmit data about other persons to us, you are obliged to ensure data protection vis-à-vis such persons and to ensure the accuracy of such personal data.
      </p>
      <p>
        We also process personal data that we receive from third parties, obtain from publicly accessible sources or collect in the course of providing our services, insofar as and to the extent that such processing is permissible for legal reasons.
      </p>
      <h3 id="third">2.4. Processing of personal data by third parties, including abroad</h3>
      <p>
        We may have personal data processed by commissioned third parties or process it jointly with third parties as well as with the help of third parties or transmit it to third parties. Such third parties are providers whose services we use. We also ensure appropriate data protection for such third parties.
      </p>
      <p>
        Such third parties are <em>generally</em> located in Switzerland and the European Economic Area (EEA). However, such third parties may also be located in other states and territories on earth as well as elsewhere in the universe, provided that their data protection laws are in accordance with <a href="https://www.edoeb.admin.ch/edoeb/en/home/data-protection/handel-und-wirtschaft/transborder-data-flows.html" rel="nofollow noopener noreferrer" target="_blank">the assessment of the Federal Data Protection and Information Commissioner</a> (FDPIC) and - if and to the extent that the General Data Protection Regulation (GDPR) is applicable - in accordance with <a href="https://ec.europa.eu/info/law/law-topic/data-protection/data-transfers-outside-eu/adequacy-protection-personal-data-non-eu-countries_en" rel="nofollow noopener noreferrer" target="_blank">the assessment of the European Commission</a> - ensures adequate data protection, or if adequate data protection is ensured for other reasons, such as by an appropriate contractual agreement, in particular on the basis of standard contractual clauses, or by an appropriate certification. Exceptionally, such a third party may be located in a country without adequate data protection, provided that the requirements under data protection law, such as the explicit consent of the data subject, are met.
      </p>
      <h2 id="rights">3. Rights of data subjects</h2>
      <p>
        Data subjects whose personal data we process have rights under Swiss data protection law. These include the right to information as well as the right to rectification, deletion or blocking of the personal data to be processed.
      </p>
      <p>
        Data subjects whose personal data we process may - if and to the extent that the General Data Protection Regulation (GDPR) is applicable - obtain confirmation free of charge as to whether we are processing their personal data and, if so, request information about the processing of their personal data, have the processing of their personal data restricted, exercise their right to data portability and have their personal data corrected, deleted ("right to be forgotten"), blocked or completed.
      </p>
      <p>
        Data subjects whose personal data we process may - if and insofar as the GDPR applies - revoke consent they have given at any time with future effect and object to the processing of their personal data at any time.
      </p>
      <p>
        Affected persons whose personal data we process have a right of appeal to a competent supervisory authority. The supervisory authority for data protection in Switzerland is the <a href="https://www.edoeb.admin.ch/edoeb/en/home/the-fdpic/contact.html" rel="nofollow noopener noreferrer" target="_blank">Federal Data Protection and Information Commissioner</a> (FDPIC).
      </p>
      <h2 id="security">4. Data security</h2>
      <p>
        We take appropriate and suitable technical and organisational measures to ensure data protection and data security. However, despite such measures, the processing of personal data on the Internet can always have security gaps. We can therefore not guarantee absolute data security.
      </p>
      <p>
        Access to our online offer is made via transport encryption (SSL / TLS, in particular via Hypertext Transfer Protocol Secure, abbreviated HTTPS). Most browsers indicate transport encryption with a padlock in the address bar.
      </p>
      <p>
        Access to our online offer - like <em>any</em> Internet use - to mass surveillance without any reason or suspicion, as well as other surveillance by security authorities in Switzerland, the European Union (EU), the United States of America (USA) and other countries. We cannot directly influence the corresponding processing of personal data by secret services, police agencies and other security authorities.
      </p>
      <h2 id="website">5. Use of the website</h2>
      <h3 id="cookies">5.1. Cookies</h3>
      <p>
        We may use cookies for our website. Cookies - both our own cookies (first-party cookies) and cookies from third parties whose services we use (third-party cookies) - are pieces of data that are stored in your browser. Such stored data may not be limited to traditional cookies in text form. Cookies cannot run programs or transmit malware such as Trojans and viruses.
      </p>
      <p>
        Cookies can be stored temporarily in your browser as "session cookies" when you visit our website or for a certain period of time as so-called "permanent cookies"". "Session cookies" are automatically deleted when you close your browser. "Permanent cookies" have a specific storage period. Specifically, they enable us to recognise your browser the next time you visit our website and thus, for example, to measure the reach of our website. However, "permanent cookies" can also be used for online marketing, for example.
      </p>
      <p>
        You can deactivate or delete cookies in full or in part in your browser settings at any time. Without cookies, our website may no longer be fully available. We actively request your express consent for the use of cookies, if and when necessary.
      </p>
      <p>
        For cookies used for performance and reach measurement or for advertising, a general objection ("opt-out") is possible for numerous services via the <a href="https://optout.networkadvertising.org/" target="_blank" rel="noopener noreferrer">Network Advertising Initiative</a> (NAI), <a href="https://optout.aboutads.info/" target="_blank" rel="noopener noreferrer">YourAdChoices</a> (Digital Advertising Alliance) or <a href="https://www.youronlinechoices.com/" target="_blank" rel="noopener noreferrer">Your Online Choices</a> (European Interactive Digital Advertising Alliance, EDAA).
      </p>
      <h3 id="logfiles">5.2. Server logfiles</h3>
      <p>
        We may collect the following information for each access to our website, provided that this information is transmitted by your browser to our server infrastructure or can be determined by our web server: Date and time including time zone, Internet Protocol (IP) address, access status (HTTP status code), operating system including user interface and version, browser including language and version, individual sub-page of our website accessed including amount of data transferred, website last accessed in the same browser window (referer or referrer).</p>
      <p>
        We store such information, which may also constitute personal data, in server log files. This information is necessary in order to provide our online service in a permanent, user-friendly and reliable manner and to ensure data security and thus in particular the protection of personal data - also by third parties or with the help of third parties.</p>
      <h2 id="newsletter">6. Notifications and communications</h2>
      <p>
        We send notifications and communications such as newsletters via email.
      </p>
      <h3 id="newsletter-measurement">6.1. Success measurement and reach measurement</h3>
      <p>
        Notifications and communications may contain web links or tracking pixels that record whether an individual communication has been opened and which web links have been clicked on. Such web links and tracking pixels may also track usage of notifications and communications on a personal basis. We need this statistical recording of usage for performance and reach measurement in order to be able to offer notifications and communications effectively and in a user-friendly manner based on the needs and reading habits of the recipients.
      </p>
      <h3 id="newsletter-consent">6.2. Consent and objection</h3>
      <p>
        You must expressly consent to the use of your e-mail address and other contact addresses, unless the use is permitted for other legal reasons. For any consent to receive e-mails, we use the "double opt-in" procedure where possible, i.e. you will receive an e-mail with a web link that you must click to confirm, so that no misuse by unauthorised third parties can take place. We may log such consents, including Internet Protocol (IP) address, date and time, for evidence and security reasons.
      </p>
      <p>
        You can unsubscribe from notifications and communications - such as newsletters - at any time. This does not apply to notifications and communications that are absolutely necessary for our services. By unsubscribing, you can object to the statistical recording of usage for performance and reach measurement.
      </p>
      <h3 id="newsletter-services">6.3. Service providers for notifications and communications</h3>
      <p>
        We send notifications and communications via third-party services or with the help of service providers. Cookies may also be used in the process. We also ensure appropriate data protection for such services.
      </p>
      <p>
        We use in particular:
      </p>
      <p>
        <div className={css.list}>
          <strong><a href="https://mailchimp.com/" rel="nofollow noopener noreferrer" target="_blank">Mailchimp:</a></strong> Communication platform; Provider: The Rocket Science Group LLC d/b/a Mailchimp (USA); Privacy information: <a href="https://mailchimp.com/legal/privacy/" rel="nofollow noopener noreferrer" target="_blank">Privacy Policy</a>; <a href="https://mailchimp.com/help/mailchimp-european-data-transfers/" rel="nofollow noopener noreferrer" target="_blank">Mailchimp and European Data Transfers</a>.
        </div>
      </p>
      <h2 id="social-media">7. Social Media</h2>
      <p>
        We are present on social media platforms and other online platforms in order to be able to communicate with interested persons and to inform them about our offer. Personal data may also be processed outside of Switzerland and the European Economic Area (EEA).
      </p>
      <p>
        The General Terms and Conditions (GTC) and Terms of Use as well as Privacy Policy and other provisions of the individual operators of such online platforms also apply in each case. These provisions inform in particular about the rights of data subjects, which include in particular the right to information.
      </p>
      <p>
        We are jointly responsible with Facebook Ireland Limited in Ireland for our <strong>Social Media presence on Facebook</strong>, including the so-called Page Insights, if and to the extent that the GDPR is applicable. Page insights provide information about how visitors interact with our Facebook presence. We use Page Insights to help us deliver our social media presence on Facebook in an effective and user-friendly way.</p>
      <p>
        Further information on the nature, scope and purpose of data processing, information on the rights of data subjects and the contact details of Facebook as well as Facebook's data protection officer can be found in the <a href="https://www.facebook.com/about/privacy" rel="nofollow noopener noreferrer" target="_blank">Data Policy of Facebook</a>. We have entered into the so-called <a href="https://www.facebook.com/legal/controller_addendum" rel="nofollow noopener noreferrer" target="_blank">Controller Addendum</a> with Facebook and have thereby agreed in particular that Facebook is responsible for ensuring the rights of data subjects. For the so-called Page Insights, the corresponding information can be found on the pages <a href="https://www.facebook.com/legal/terms/page_controller_addendum" rel="nofollow noopener noreferrer" target="_blank">Information about Page Insights</a> and <a href="https://www.facebook.com/legal/terms/information_about_page_insights_data" rel="nofollow noopener noreferrer" target="_blank">Information about Page Insights Data</a> from Facebook.
      </p>
      <p>
        Users of social media platforms have the option of logging in or registering for our online offering (<strong>"Social Login"</strong>) with their corresponding user account. The respective terms and conditions of the social media platforms concerned, such as the General Terms and Conditions (GTC) and Terms of Use or Privacy Policy, apply.
      </p>
      <h2 id="analytics">8. Performance and Reach Measurement</h2>
      <p>
        We use <strong> plausible.io</strong> to measure the reach our online offering. The necessary server infrastructure is operated by the Estonian company Plausible Insights OÜ. No cookies are used in this process.
      </p>
      <p>
        plausible.io has <a href="https://plausible.io/privacy-focused-web-analytics" rel="nofollow noopener noreferrer" target="_blank">"100% data ownership"</a>, which means that plausible.io does not collect data across websites and does not pass on data about our online offering to third parties. Furthermore, your Internet Protocol (IP) address is not used for analysis. Further information on the type, scope and purpose of data processing can be found in the <a href="https://plausible.io/privacy" rel="nofollow noopener noreferrer" target="_blank">Privacy Policy of plausible.io</a>.
      </p>
      <h2 id="services">9. Third party services</h2>
      <p>
        We use third party services to provide our service in a durable, user-friendly, secure and reliable manner. Such services are also used to embed content on our website. Such services - for example hosting and storage services, video services and payment services - require your Internet Protocol (IP) address, as such services cannot otherwise transmit the corresponding content. Such services may be located outside of Switzerland and the European Economic Area (EEA), provided adequate data protection is guaranteed.
      </p>
      <p>
        For their own security, statistical and technical purposes, third parties whose services we use may also process data related to our offering as well as from other sources - including, but not limited to, cookies, log files and counting pixels - in aggregated, anonymised or pseudonymised form.
      </p>
      <h3 id="infrastructure">9.1. Digital infrastructure</h3>
      <p>
        We use services from third parties in order to be able to use the digital infrastructure required for our offer. This includes, for example, hosting and storage services from specialised providers.
      </p>
      <p>
        We use in particular:
      </p>
      <p>
        <div className={css.list}>
          <strong><a href="https://aws.amazon.com/" rel="nofollow noopener noreferrer" target="_blank">Amazon Web Services (AWS):</a></strong> Storage space and other infrastructure; Amazon Web Services Inc. (USA) / Amazon Web Services EMEA SARL (Luxembourg); <a href="https://aws.amazon.com/privacy/" rel="nofollow noopener noreferrer" target="_blank">Privacy Notice</a>, <a href="https://aws.amazon.com/compliance/data-privacy/" rel="nofollow noopener noreferrer" target="_blank">Data Privacy</a>.
        </div>
        <div className={css.list}>
          <strong><a href="https://www.heroku.com" rel="nofollow noopener noreferrer" target="_blank"> HEROKU:</a></strong> Storage and other infrastructure; salesforce.com inc. (USA); <a href="https://www.salesforce.com/company/privacy/" rel="nofollow noopener noreferrer" target="_blank">Privacy Policy</a>.
        </div>
        <div className={css.list}>
          <strong><a href="https://www.sharetribe.com" rel="nofollow noopener noreferrer" target="_blank"> Sharetribe:</a></strong> Storage and other infrastructure; Sharetribe Oy (Finland); <a href="https://www.sharetribe.com/privacy-policy/" rel="nofollow noopener noreferrer" target="_blank">Privacy Policy</a>.
        </div>
      </p>
      <h3 id="kontaktmoeglichkeiten">9.2. Contact options</h3>
      <p>
        We use third party services to better communicate with you and others. We also ensure appropriate data protection with such third parties.
      </p>
      <p>
        We use in particular:
      </p>
      <p>
        <div className={css.list}>
          <strong><a href="https://www.freshworks.com/" rel="nofollow noopener noreferrer" target="_blank">Freshworks:</a></strong> Customer Relationship Management (CRM) and Customer Service; Freshworks Inc. (USA); <a href="https://www.freshworks.com/privacy/" rel="nofollow noopener noreferrer" target="_blank">Privacy Policy</a>.
        </div>
      </p>
      <h3 id="maps">9.3. Maps</h3>
      <p>
        We use <strong>Mapbox</strong> to embed maps on our website. Cookies may also be used in this process. Mapbox is a service of the American Mapbox Inc. or the American Mapbox International Inc. Information about the type, scope and purpose of data processing can be found in <a href="https://www.mapbox.com/privacy/">Mapbox's Privacy Policy</a>.</p>
      <h3 id="media">9.4. Audiovisual media</h3>
      <p>We use third-party services to enable the direct playback of audiovisual media, such as music or videos, on our website.</p>
      <p>
        We use in particular:
      </p>
      <p>
        <div className={css.list}>
          <strong><a href="https://vimeo.com/" rel="nofollow noopener noreferrer" target="_blank">Vimeo:</a></strong> Videos; Vimeo Inc. (USA); <a href="https://vimeo.com/privacy" rel="nofollow noopener noreferrer" target="_blank">Privacy Policy</a>, <a href="https://vimeo.zendesk.com/hc/en-us/sections/203915088-Privacy" rel="nofollow noopener noreferrer" target="_blank">Privacy</a>.
        </div>
      </p>
      <h3 id="payments">9.5. Payments</h3>
      <p>
        We use payment service providers to process payments from our customers securely and reliably. The terms and conditions of the respective payment service providers, such as General Terms and Conditions (GTC) or Privacy Policy, apply to the processing.
      </p>
      <p>
        We use in particular:
      </p>
      <p>
        <div className={css.list}>
          <strong><a href="https://stripe.com/" rel="nofollow noopener noreferrer" target="_blank">Stripe:</a></strong> Processing of payments; Stripe Inc. (USA) / Stripe Payments UK Limited (UK) / Stripe Technology Europe Limited (Ireland); <a href="https://stripe.com/privacy-center/legal" rel="nofollow noopener noreferrer" target="_blank">Stripe Privacy Center</a>, <a href="https://stripe.com/de-ch/privacy" rel="nofollow noopener noreferrer" target="_blank">Global Privacy Policy</a>, <a href="https://stripe.com/cookies-policy/legal" rel="nofollow noopener noreferrer" target="_blank">Cookie Policy</a>.
        </div>
      </p>
        <h2 id="final provisions">10. Final provisions</h2>
        <p>
          We may amend and supplement this Privacy Policy at any time. We will inform about such adjustments and additions in an appropriate form, in particular by publishing the respective current Privacy Policy on our website.
        </p>
    </div>
  );
};

PrivacyPolicy.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

PrivacyPolicy.propTypes = {
  rootClassName: string,
  className: string,
};

export default PrivacyPolicy;
