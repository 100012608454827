import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconBook.module.css';

const IconBook = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg
      className={classes}
      width="100"
      height="100"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M45.6,2.514H2.4A1.654,1.654,0,0,0,.75,4.168V32.234A1.654,1.654,0,0,0,2.4,33.888H45.5a1.751,1.751,0,0,0,1.752-1.752V4.168A1.654,1.654,0,0,0,45.6,2.514Z"
        fill="#0099e2"
      />
      <rect height="22.83" fill="#3a3c40" width="40.36" x="3.82" y="5.38" />
      <path d="M44.18,5.38V26.13H12.88A5.983,5.983,0,0,1,6.9,20.15V5.38Z" fill="#484a50" />
      <polygon
        points="32.09 41.74 32.09 45.49 15.91 45.49 15.91 41.74 19.77 41.74 20.45 35.4 20.61 33.89 27.39 33.89 27.55 35.4 28.23 41.74 32.09 41.74"
        fill="#007db8"
      />
      <polygon
        points="28.226 41.737 19.774 41.737 15.908 41.737 15.908 45.486 32.092 45.486 32.092 41.737 28.226 41.737"
        fill="#0099e2"
      />
      <polygon points="27.55 35.4 20.45 35.4 20.61 33.89 27.39 33.89 27.55 35.4" fill="#00628f" />
      <path
        d="M38.08,21.44a.405.405,0,0,1,.05-.09v-.01a7.611,7.611,0,0,0,.68-1.52,7.5,7.5,0,0,0-11.36-8.45,7.313,7.313,0,0,0-2.19,2.3,7.495,7.495,0,0,0,6.45,11.39,4.032,4.032,0,0,0,.6-.04.5.5,0,0,0,.17-.01,2.226,2.226,0,0,0,.36-.05,5.708,5.708,0,0,0,.82-.18c.12-.03.23-.07.35-.11a1.426,1.426,0,0,0,.27-.09c.11-.04.23-.09.34-.14a7.828,7.828,0,0,0,.91-.47l.01.01,2.15,2.15h5.07C40.58,23.96,38.08,21.45,38.08,21.44Z"
        fill="#3a3c40"
      />
      <path
        d="M46.38,31a1.711,1.711,0,0,1-.47.82,1.8,1.8,0,0,1-2.53,0l-3.61-3.61h4.41v-.65l1.39,1.39.34.34A1.753,1.753,0,0,1,46.38,31Z"
        fill="#051b22"
      />
      <path
        d="M44.18,27.56v.65H39.77l-2.08-2.08h5.07C43.26,26.63,43.75,27.12,44.18,27.56Z"
        fill="#27282b"
      />
      <path
        d="M46.72,28.53l-.34-.34c-1.5-1.5-7.49-7.5-7.49-7.51a.405.405,0,0,1,.05-.09v-.01a7.611,7.611,0,0,0,.68-1.52,7.5,7.5,0,0,0-11.36-8.45,7.313,7.313,0,0,0-2.19,2.3A7.495,7.495,0,0,0,32.52,24.3a4.032,4.032,0,0,0,.6-.04.5.5,0,0,0,.17-.01,2.226,2.226,0,0,0,.36-.05,5.708,5.708,0,0,0,.82-.18c.12-.03.23-.07.35-.11a1.426,1.426,0,0,0,.27-.09c.11-.04.23-.09.34-.14a7.828,7.828,0,0,0,.91-.47l.01.01,7.84,7.84a1.8,1.8,0,0,0,2.53,0,1.711,1.711,0,0,0,.47-.82A1.753,1.753,0,0,0,46.72,28.53Z"
        fill="#a9abb1"
      />
      <path
        d="M39.62,19.06a7.611,7.611,0,0,1-.68,1.52v.01a.405.405,0,0,0-.05.09.01.01,0,0,0-.01.01c-.05.08-.1.17-.16.25a7.38,7.38,0,0,1-.96,1.18l-.05.05a6.866,6.866,0,0,1-1.36,1.05,5.27,5.27,0,0,1-.92.46c-.11.05-.23.1-.34.14a1.426,1.426,0,0,1-.27.09c-.12.04-.23.08-.35.11a5.708,5.708,0,0,1-.82.18,2.226,2.226,0,0,1-.36.05.5.5,0,0,1-.17.01,4.032,4.032,0,0,1-.6.04,7.495,7.495,0,0,1-6.45-11.39,7.313,7.313,0,0,1,2.19-2.3,7.5,7.5,0,0,1,11.36,8.45Z"
        fill="#dfe0e2"
      />
      <path
        d="M39.62,19.06a7.444,7.444,0,0,1-.74,1.63c-.05.08-.1.17-.16.25a7.5,7.5,0,0,1-10.61-10.1c.05-.08.1-.16.15-.23a7.5,7.5,0,0,1,11.36,8.45Z"
        fill="#f5f5f8"
      />
      <path
        d="M47.19,30.24a1.593,1.593,0,0,1-.57.1,1.785,1.785,0,0,1-1.27-.52l-7.64-7.65.05-.05a7.38,7.38,0,0,0,.96-1.18c.06-.08.11-.17.16-.25a.01.01,0,0,1,.01-.01c0,.01,5.99,6.01,7.49,7.51l.34.34A1.753,1.753,0,0,1,47.19,30.24Z"
        fill="#dfe0e2"
      />
    </svg>
  );
};

IconBook.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

IconBook.propTypes = { rootClassName: string, className: string };

export default IconBook;
