import React from 'react';
import PropTypes from 'prop-types';

const IconLogo = props => {
  const { className, format, ...rest } = props;

  if (format === 'desktop') {
    return (
      <svg
        className={className}
        {...rest}
        height="100"
        viewBox="0 0 1417.3 1048.8"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill="#221B57"
          d="M1402.9,17.9l-13,83.2h-94.2l-5.5,34.9h86.1L1364,215h-86.1l-5.6,36.3h97.6l-13.5,85.6h-213.3l50.3-319.1
          H1402.9z"
        />
        <path
          fill="#0099E2"
          d="M1094.4,212.1c24-3.3,33.3-7.2,48-18.6c15-11.8,25.7-29,30.9-48.1l3.6-22.5c1-30-11.8-59.6-34.5-79.1
          c-23.3-19.1-47.4-25.8-99.5-25.8H916L865.7,337h115.7l8.1-53.6c1.8-12,3.3-27.8,3.8-42.1c0.3-8.6,0.5-12.9,0.3-23.4l3.4-0.5
          c2,12,6.6,29.2,10.9,42.6l26,77h109l3.8-24L1094.4,212.1z M1018.7,160.9h-9.5l9.2-57.4h9.1c25.4,0,36.6,8.1,35.9,25.8
          C1062.6,148.5,1045.5,160.9,1018.7,160.9z"
        />
        <path
          fill="#221B57"
          d="M834.2,17.9h-132L566.9,290.3l43-272.4H494.2L476.9,127H409l17.2-109.1H310.5l-29.8,188.8
          c-4.2-20.5-14.9-38.5-30.5-49.1c-11.2-8.1-29.1-15.3-50.9-21c-28.9-7.2-34.6-10.5-34.2-20.6c0.3-9.6,10.2-16.7,22.6-16.7
          c24.9,0,49.4,10.5,68.8,28.7L302,43.3c-8.4-4.8-15-8.1-19.7-10.5c-31.5-14.9-65.7-22.5-98.2-22.5C111.3,10.3,51.2,61,49,124.6
          c-1.3,36.8,13.6,63.6,43.2,78.4c11.3,6.2,23.1,10.1,44.9,14.3c20.9,4.8,29.7,11.5,29.3,22.5c-0.4,11.5-11.2,18.7-27.4,18.7
          c-30.1,0-60.2-14.3-78.6-37.8l-46.1,86.1c12.7,7.2,22.5,11.9,29.1,15.3c30.6,14.3,66.2,23,95.8,23c57.7,0,108.1-29.3,130.7-70.9
          l-9.9,62.8h115.8l17.9-116.2h68.4L443.8,337h100h15.8h108.6l16.8-42.6h78.5l0.9,42.6h101.4l8.5-54.1L834.2,17.9z M714.2,221.7
          l23.5-57.9c7.8-18.6,11.1-29.2,16-47.8h3.8c-1.3,10.5-1.9,13.9-2.3,25.3c-0.3,9.1-0.1,17.2,0.2,22.5l2.8,57.9H714.2z"
        />
        <path
          fill="#221B57"
          d="M1316.8,563.5l65,124.8h-125.3l-26-77c-4.3-13.4-9-30.6-10.9-42.6l-3.4,0.5c0.1,10.5,0,14.8-0.3,23.4
          c-0.5,14.3-2,30.1-3.8,42.1l-8.1,53.6h-115.7l50.3-319.1h126.8c52.1,0,76.3,6.7,99.5,25.8c22.8,19.6,35.6,49.3,34.5,79.4
          c-1,27.7-13.9,54.1-34.5,70.3C1350,556.3,1340.8,560.1,1316.8,563.5z M1240.7,454.9l-9.2,57.4h9.5c26.8,0,44-12.5,44.6-31.6
          c0.6-17.7-10.6-25.8-35.9-25.8H1240.7z"
        />
        <path
          fill="#0099E2"
          d="M1138.5,369.3h-106.8L1003,550.1c-6,34.9-16.5,48.8-37.3,48.8c-17.1,0-26.5-10.5-25.7-29.7
          c0.2-3.8,1.4-11.9,3.3-23.4l27.9-176.5H861L831.4,553c-1.3,8.6-2.1,16.7-2.5,25.4c-1.7,38.3,7.1,63.1,30.1,83.7
          c25.1,22.5,61,34.5,104.7,34.5c42.8,0,75.7-12,103.3-37.3c17-15.9,26.6-29.5,34-53.4L1138.5,369.3z"
        />
        <path
          fill="#221B57"
          d="M829,578.3c0.4-8.6,1.2-16.7,2.5-25.4l13.4-82.9c-7.7-25.4-21.7-47.2-41.9-64.4
          c-32.5-28.2-76.4-44-122.4-44c-51.8,0-98.4,20.9-131.5,54.6l36-47H445.8l-31.7,58.4c-5.6,11-9.3,19.6-12.5,30.1h-4.3
          c-0.4-16.3-1.5-24.4-4.9-36.3l-14.4-52.2H251.1L330,550.1l-22.1,138.2h114.8L444.2,553l77.7-101.4c-14,24.2-22.4,51.9-23.4,81.8
          c-3.2,92.3,71.8,163.1,172.7,163.1c69.6,0,128.5-36.2,158.7-91C828.9,597.2,828.5,588.3,829,578.3z M669.5,596
          c-32.5,0-55.5-26.3-54.3-61.7c1.3-36.8,30.6-67,65.5-67c32.1,0,55.5,26.8,54.3,60.7C733.6,566.9,705.8,596,669.5,596z"
        />
        <path
          fill="#0099E2"
          d="M1103.8,719.5l-49.3,319H947.4l-49.1-110c-10.1-23.4-16.4-41.6-21.9-64.6l-1.6-5.7H871
            c0.9,14.3,0.9,24.9,0.4,38.3c-0.5,12.4-1.3,23.9-2.6,32l-13.7,110H743.9l49.3-319h111.3l49.7,120.1c6.3,15.3,10.8,29.2,17.2,56.5
            l2.4,10.5l4.6-0.5l-0.5-10.5c-0.7-17.7-1.2-30.6-0.7-44c0.5-12.9,1.4-25.3,3.2-37.8l11.2-94.2H1103.8z"
        />
      </svg>
    );
  }

  return (
    <svg
      className={className}
      {...rest}
      height="100"
      viewBox="0 0 1417.3 1048.8"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill="#221B57"
        d="M1402.9,17.9l-13,83.2h-94.2l-5.5,34.9h86.1L1364,215h-86.1l-5.6,36.3h97.6l-13.5,85.6h-213.3l50.3-319.1
			H1402.9z"
      />
      <path
        fill="#0099E2"
        d="M1094.4,212.1c24-3.3,33.3-7.2,48-18.6c15-11.8,25.7-29,30.9-48.1l3.6-22.5c1-30-11.8-59.6-34.5-79.1
			c-23.3-19.1-47.4-25.8-99.5-25.8H916L865.7,337h115.7l8.1-53.6c1.8-12,3.3-27.8,3.8-42.1c0.3-8.6,0.5-12.9,0.3-23.4l3.4-0.5
			c2,12,6.6,29.2,10.9,42.6l26,77h109l3.8-24L1094.4,212.1z M1018.7,160.9h-9.5l9.2-57.4h9.1c25.4,0,36.6,8.1,35.9,25.8
			C1062.6,148.5,1045.5,160.9,1018.7,160.9z"
      />
      <path
        fill="#221B57"
        d="M834.2,17.9h-132L566.9,290.3l43-272.4H494.2L476.9,127H409l17.2-109.1H310.5l-29.8,188.8
			c-4.2-20.5-14.9-38.5-30.5-49.1c-11.2-8.1-29.1-15.3-50.9-21c-28.9-7.2-34.6-10.5-34.2-20.6c0.3-9.6,10.2-16.7,22.6-16.7
			c24.9,0,49.4,10.5,68.8,28.7L302,43.3c-8.4-4.8-15-8.1-19.7-10.5c-31.5-14.9-65.7-22.5-98.2-22.5C111.3,10.3,51.2,61,49,124.6
			c-1.3,36.8,13.6,63.6,43.2,78.4c11.3,6.2,23.1,10.1,44.9,14.3c20.9,4.8,29.7,11.5,29.3,22.5c-0.4,11.5-11.2,18.7-27.4,18.7
			c-30.1,0-60.2-14.3-78.6-37.8l-46.1,86.1c12.7,7.2,22.5,11.9,29.1,15.3c30.6,14.3,66.2,23,95.8,23c57.7,0,108.1-29.3,130.7-70.9
			l-9.9,62.8h115.8l17.9-116.2h68.4L443.8,337h100h15.8h108.6l16.8-42.6h78.5l0.9,42.6h101.4l8.5-54.1L834.2,17.9z M714.2,221.7
			l23.5-57.9c7.8-18.6,11.1-29.2,16-47.8h3.8c-1.3,10.5-1.9,13.9-2.3,25.3c-0.3,9.1-0.1,17.2,0.2,22.5l2.8,57.9H714.2z"
      />
      <path
        fill="#221B57"
        d="M1316.8,563.5l65,124.8h-125.3l-26-77c-4.3-13.4-9-30.6-10.9-42.6l-3.4,0.5c0.1,10.5,0,14.8-0.3,23.4
			c-0.5,14.3-2,30.1-3.8,42.1l-8.1,53.6h-115.7l50.3-319.1h126.8c52.1,0,76.3,6.7,99.5,25.8c22.8,19.6,35.6,49.3,34.5,79.4
			c-1,27.7-13.9,54.1-34.5,70.3C1350,556.3,1340.8,560.1,1316.8,563.5z M1240.7,454.9l-9.2,57.4h9.5c26.8,0,44-12.5,44.6-31.6
			c0.6-17.7-10.6-25.8-35.9-25.8H1240.7z"
      />
      <path
        fill="#0099E2"
        d="M1138.5,369.3h-106.8L1003,550.1c-6,34.9-16.5,48.8-37.3,48.8c-17.1,0-26.5-10.5-25.7-29.7
			c0.2-3.8,1.4-11.9,3.3-23.4l27.9-176.5H861L831.4,553c-1.3,8.6-2.1,16.7-2.5,25.4c-1.7,38.3,7.1,63.1,30.1,83.7
			c25.1,22.5,61,34.5,104.7,34.5c42.8,0,75.7-12,103.3-37.3c17-15.9,26.6-29.5,34-53.4L1138.5,369.3z"
      />
      <path
        fill="#221B57"
        d="M829,578.3c0.4-8.6,1.2-16.7,2.5-25.4l13.4-82.9c-7.7-25.4-21.7-47.2-41.9-64.4
			c-32.5-28.2-76.4-44-122.4-44c-51.8,0-98.4,20.9-131.5,54.6l36-47H445.8l-31.7,58.4c-5.6,11-9.3,19.6-12.5,30.1h-4.3
			c-0.4-16.3-1.5-24.4-4.9-36.3l-14.4-52.2H251.1L330,550.1l-22.1,138.2h114.8L444.2,553l77.7-101.4c-14,24.2-22.4,51.9-23.4,81.8
			c-3.2,92.3,71.8,163.1,172.7,163.1c69.6,0,128.5-36.2,158.7-91C828.9,597.2,828.5,588.3,829,578.3z M669.5,596
			c-32.5,0-55.5-26.3-54.3-61.7c1.3-36.8,30.6-67,65.5-67c32.1,0,55.5,26.8,54.3,60.7C733.6,566.9,705.8,596,669.5,596z"
      />
      <path
        fill="#0099E2"
        d="M1103.8,719.5l-49.3,319H947.4l-49.1-110c-10.1-23.4-16.4-41.6-21.9-64.6l-1.6-5.7H871
				c0.9,14.3,0.9,24.9,0.4,38.3c-0.5,12.4-1.3,23.9-2.6,32l-13.7,110H743.9l49.3-319h111.3l49.7,120.1c6.3,15.3,10.8,29.2,17.2,56.5
				l2.4,10.5l4.6-0.5l-0.5-10.5c-0.7-17.7-1.2-30.6-0.7-44c0.5-12.9,1.4-25.3,3.2-37.8l11.2-94.2H1103.8z"
      />
    </svg>
  );
};

const { string } = PropTypes;

IconLogo.defaultProps = {
  className: null,
};

IconLogo.propTypes = {
  className: string,
};

export default IconLogo;
