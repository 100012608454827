import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconEmailAttention.module.css';

const IconEmailAttention = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg
      className={classes}
      width="64"
      height="64"
      viewBox="0 0 64 64"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect height="44" fill="#007db8" width="62" x="1" y="16" />
      <polygon points="32 36 1 60 63 60 32 36" fill="#0099e2" />
      <path d="M1,16,30.648,38.953a2.206,2.206,0,0,0,2.7,0L63,16Z" fill="#33beff" />
      <circle cx="32" cy="16" r="12" fill="#ffffff" />
      <path d="M32,8a1,1,0,0,0-1,1v9a1,1,0,0,0,2,0V9A1,1,0,0,0,32,8Z" fill="#ffaa00" />
      <path d="M32,21a1,1,0,0,0-1,1v1a1,1,0,0,0,2,0V22A1,1,0,0,0,32,21Z" fill="#ffaa00" />
    </svg>
  );
};

IconEmailAttention.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

IconEmailAttention.propTypes = { rootClassName: string, className: string };

export default IconEmailAttention;
