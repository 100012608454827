import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconDrink.module.css';

const IconDrink = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg
      className={classes}
      width="100"
      height="100"
      viewBox="0 0 512 512"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M226.927,108.472a8,8,0,0,1-7.32-4.763l-13.633-30.8a8,8,0,0,1,14.631-6.477l13.633,30.8a8,8,0,0,1-7.311,11.24Z"
        fill="#a9abb1"
      />
      <path
        d="M254.151,107.564a8.005,8.005,0,0,1-7.718-10.123L255.3,65.208a8,8,0,1,1,15.427,4.242l-8.865,32.233A8,8,0,0,1,254.151,107.564Z"
        fill="#a9abb1"
      />
      <path
        d="M207.939,128.012a7.956,7.956,0,0,1-3.609-.866l-29.82-15.111a8,8,0,1,1,7.232-14.272l29.82,15.112a8,8,0,0,1-3.623,15.137Z"
        fill="#a9abb1"
      />
      <path
        d="M157.07,291.479c-.184,0-.367.02-.551.02a38.094,38.094,0,0,1-7.54-.76,37.2,37.2,0,0,1-7.6-2.414l-11.048,54.62,17.2-4.319Z"
        fill="#f5f5f8"
      />
      <path
        d="M328.29,262.349l9.053,36.776a7.963,7.963,0,0,0,2.164,3.79,71.758,71.758,0,0,1-1.1-12.816l13.619,1.746-8.211-33.357a37.377,37.377,0,0,1-7.631,2.793A38.042,38.042,0,0,1,328.29,262.349Z"
        fill="#f5f5f8"
      />
      <path
        d="M472.279,340.849l-45.29-43.43L334.533,285.57s-1,13.652,2.6,24.089L333.66,411.683l80.5,4.908c24.025,2.479,40.808-8.633,40.808-8.633L504,421.518v-73.5Z"
        fill="#fcd3c4"
      />
      <path
        d="M372.6,337.578a14.542,14.542,0,0,1-14.484,15.588l-48.248.068c-6.8-.127-12.764-6.755-13.33-14.805s4.484-14.471,11.281-14.344l50.277,0a14.542,14.542,0,0,1,14.5,13.495Z"
        fill="#fbc5b1"
      />
      <path
        d="M318.53,353.146c-6.8-.127-11.847,6.3-11.281,14.344s6.534,14.678,13.33,14.805l39.608.169A14.54,14.54,0,0,0,374.75,366.83l-.018-.237a14.542,14.542,0,0,0-14.5-13.449Z"
        fill="#fbc5b1"
      />
      <path
        d="M377.051,396.332l-.035-.432a14.541,14.541,0,0,0-14.5-13.367l-30.91,0c-6.8-.127-12.473,6.283-11.907,14.333s7.16,14.688,13.956,14.815l4.324.08,24.405.283A14.54,14.54,0,0,0,377.051,396.332Z"
        fill="#fbc5b1"
      />
      <path d="M320.579,382.3l39.608.169Z" fill="#fde2d8" />
      <path
        d="M334.645,296.422l-26.392-1.19c-6.8-.127-14.317,6.25-13.751,14.3s6.277,14.426,13.074,14.553h51.057c-5.529-1.05-10.451-2.359-13.668-3.985C337.613,316.382,335.3,305.092,334.645,296.422Z"
        fill="#fbc5b1"
      />
      <path
        d="M425.866,364.486c-22.376-6.374-30.247-36.509-30.247-36.509s-38.156-1.563-50.654-7.879c-7.351-3.716-9.664-15.007-10.322-23.676l-6.353-.286s-.648,19.644,11.869,28.906,50.788,9.437,50.788,9.437,8.564,18.175,16.078,25.34S425.866,364.486,425.866,364.486Z"
        fill="#fab79e"
      />
      <path
        d="M245.585,134.718l17.166,70.021c7.612,31.02,39.728,68.958,74.39,60.425S382.7,208.2,375.086,177.175l-17.167-70.02Z"
        fill="#dfe0e2"
      />
      <path
        d="M343.817,258.488a37.377,37.377,0,0,1-7.631,2.793,38.042,38.042,0,0,1-7.9,1.068l2.916,11.846a48.989,48.989,0,0,0,15.532-3.838Z"
        fill="#dfe0e2"
      />
      <path
        d="M121.06,137.615,106.7,208.264c-6.356,31.3,6.5,79.319,41.484,86.4s65.513-32.077,71.869-63.379l14.36-70.649Z"
        fill="#dfe0e2"
      />
      <path
        d="M146.6,302.5a50.251,50.251,0,0,0,8.048.95l2.422-11.972c-.184,0-.367.02-.551.02a38.094,38.094,0,0,1-7.54-.76,37.2,37.2,0,0,1-7.6-2.414L138.961,300.3A49.649,49.649,0,0,0,146.6,302.5Z"
        fill="#dfe0e2"
      />
      <path
        d="M234.414,160.632,133.74,140.19l-12.7,62.462c-6.356,31.3,6.5,79.318,41.485,86.4a42.146,42.146,0,0,0,17.449-.154c20.367-11.6,35.77-36.4,40.076-57.612Z"
        fill="#f5f5f8"
      />
      <path
        d="M150.17,325.59l-7.292,1.832a32.635,32.635,0,0,0,5.061-23.99l-2.41-13.577a37.728,37.728,0,0,1-4.145-1.531l-11.048,54.621,17.2-4.32Z"
        fill="#dfe0e2"
      />
      <path
        d="M175.967,379.577l8.679-2.146a12.724,12.724,0,0,0-6.2-24.681,12.54,12.54,0,0,0,9.28-15.285c-1.728-6.753-9.072-10.781-15.834-9.083L105.72,345l23.4-15.087a24.708,24.708,0,0,0,10.94-25.086L137.608,291,80.832,331.41,49.863,363.869,8,374.384v78.291L69.167,437.31l24.938,10.006a26.1,26.1,0,0,0,16.07,1.092l67.056-16.831c6.816-1.712,11.58-8.782,9.868-15.6h0c-1.712-6.816-9.229-10.832-16.045-9.12l10.633-2.67c6.744-1.694,11.307-8.7,9.66-15.453A12.534,12.534,0,0,0,175.967,379.577Z"
        fill="#fcd3c4"
      />
      <path
        d="M349.5,324.084a2,2,0,0,0-2-2H309.864a2,2,0,0,0,0,4H347.5A2,2,0,0,0,349.5,324.084Z"
        fill="#fab79e"
      />
      <path
        d="M360.112,353.162a2,2,0,0,0-2-2h0l-39.583.068a2,2,0,0,0,0,4h0l39.583-.068A2,2,0,0,0,360.112,353.162Z"
        fill="#fab79e"
      />
      <path d="M362.521,380.533l-30.3,0a2,2,0,0,0,0,4l30.3,0a2,2,0,1,0,0-4Z" fill="#fab79e" />
      <path
        d="M375.086,177.175l-17.167-70.02L258.865,131.46l15.616,63.7c7.612,31.02,39.728,68.959,74.391,60.426a41.833,41.833,0,0,0,10.617-4.21C376.222,232.563,380.714,200.11,375.086,177.175Z"
        fill="#f5f5f8"
      />
      <path
        d="M370.37,157.94c-.631,1.221-1.2,2.278-1.663,3.091a34.831,34.831,0,0,1-18.679,15.724c-22.918,8.165-45.8-6.284-65.981-10.545a70.684,70.684,0,0,0-30.7.169l9.4,38.36c7.612,31.02,39.728,68.958,74.39,60.425S382.7,208.2,375.086,177.175Z"
        fill="#007db8"
      />
      <path
        d="M375.086,177.175,370.37,157.94c-.631,1.221-1.2,2.278-1.663,3.091a34.831,34.831,0,0,1-18.679,15.724c-22.918,8.165-45.8-6.284-65.982-10.545A72.834,72.834,0,0,0,267,164.641l7.481,30.515c7.612,31.02,39.728,68.959,74.391,60.426a41.833,41.833,0,0,0,10.617-4.21C376.222,232.563,380.714,200.11,375.086,177.175Z"
        fill="#0099e2"
      />
      <path
        d="M204.557,211.462c-23.427,6.562-44.983-10.479-65.095-15.079a70.887,70.887,0,0,0-30.245-.5L106.7,208.264c-6.356,31.3,6.5,79.319,41.484,86.4s65.513-32.077,71.869-63.379L229.1,186.77C224.076,196.978,216.684,208.066,204.557,211.462Z"
        fill="#007db8"
      />
      <path
        d="M204.557,211.462c-23.427,6.562-44.983-10.479-65.095-15.079A73.982,73.982,0,0,0,122.7,194.5l-1.657,8.154c-6.356,31.3,6.5,79.318,41.485,86.4a42.146,42.146,0,0,0,17.449-.154c20.367-11.6,35.77-36.4,40.076-57.612L229.1,186.77C224.076,196.978,216.684,208.066,204.557,211.462Z"
        fill="#0099e2"
      />{' '}
    </svg>
  );
};

IconDrink.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

IconDrink.propTypes = { rootClassName: string, className: string };

export default IconDrink;
