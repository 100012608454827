import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconGetPaid.module.css';

const IconGetPaid = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg
      className={classes}
      width="100"
      height="100"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill="#fcd3c4"
        d="M45.52,22.61a12.484,12.484,0,0,1,.75,4.26c0,4.29-2.19,8.19-5.77,11.08a8.621,8.621,0,0,0-2.06,3.07,12.133,12.133,0,0,0-.81,3.67,22.778,22.778,0,0,0,.08,2.32.922.922,0,0,1-.92.99H31.38a.823.823,0,0,1-.79-1.07,7.517,7.517,0,0,0,.43-2.73,3.827,3.827,0,0,0-.45-1.37.572.572,0,0,0-.6-.28,26.973,26.973,0,0,1-4.64.4c-.75,0-1.49-.04-2.22-.1a5.66,5.66,0,0,0-1.23,2.08,13.808,13.808,0,0,0-.34,2.32.835.835,0,0,1-.83.75H15.53a.537.537,0,0,1-.53-.67,15.417,15.417,0,0,0,.39-2.08,5.017,5.017,0,0,0-.56-2.88c-.94-1.98-3.11-2.85-4.22-3.84-1.43-1.25-3.15-2.82-3.48-3.18A10.4,10.4,0,0,0,.77,32.04a.854.854,0,0,1-.75-.85V22.83a.5.5,0,0,1,.5-.5H1.66c1.94,0,3.18-.37,3.78-1.07.27-.33.77-.99,1.02-1.37a15.75,15.75,0,0,1,2.23-2.77,9.528,9.528,0,0,1-1.21-1.37A11.514,11.514,0,0,1,5.9,12.04a21.514,21.514,0,0,1,2.74.13,8.439,8.439,0,0,1,4.22,1.79,24.157,24.157,0,0,1,4.48-1.98,20.516,20.516,0,0,1,2.97-.71c.19-.04.38-.07.57-.1a44.183,44.183,0,0,1,6.26-.48,18.367,18.367,0,0,1,5.85,1.07c.23.07.44.15.65.23a19.655,19.655,0,0,1,2.45,1.08,18.711,18.711,0,0,1,7.83,6.39,2.185,2.185,0,0,0,1.36.51c1.21,0,2.24-1.1,2.69-2.66.01.15.01.31.01.46C47.98,20.08,46.94,22.02,45.52,22.61Z"
      />
      <path
        fill="#ffd36c"
        d="M34.48,7.34a6.078,6.078,0,0,1-.03.61.014.014,0,0,1,0,.02,7.253,7.253,0,0,1-1.46,3.78v.01a7.082,7.082,0,0,1-1.75,1.66h-.01a7.31,7.31,0,0,1-9.43-1.07,5.944,5.944,0,0,1-.91-1.17.01.01,0,0,0-.01-.01,7.273,7.273,0,0,1-1.06-3.19c-.02-.21-.03-.42-.03-.64a7.345,7.345,0,0,1,14.69,0Z"
      />
      <path
        fill="#fbc5b1"
        d="M33.64,11.99a8.579,8.579,0,0,1-.65,1,7.13,7.13,0,0,1-1.75,1.67h-.01a7.31,7.31,0,0,1-9.43-1.07,5.724,5.724,0,0,1-.91-1.18,6.787,6.787,0,0,1-.58-1.14c.19-.04.38-.07.57-.1a.01.01,0,0,1,.01.01,5.944,5.944,0,0,0,.91,1.17,7.31,7.31,0,0,0,9.43,1.07h.01a7.082,7.082,0,0,0,1.75-1.66C33.22,11.83,33.43,11.91,33.64,11.99Z"
      />
      <path
        fill="#fdb924"
        d="M26.275,8.381h1.519a.455.455,0,0,1,0,.909H26.275a.451.451,0,0,1-.361-.177,1.043,1.043,0,1,0-1.657,1.268,2.5,2.5,0,0,0,1.734.964V12.1a1.044,1.044,0,1,0,2.087,0v-.75a2.535,2.535,0,0,0-.284-5.055H26.275a.454.454,0,1,1,0-.908h1.519a.456.456,0,0,1,.368.186,1.043,1.043,0,1,0,1.679-1.238,2.545,2.545,0,0,0-1.763-1V2.577a1.044,1.044,0,1,0-2.087,0v.75a2.535,2.535,0,0,0,.284,5.054Z"
      />
      <path fill="#0099e2" d="M10.188,20.529v1.409a1,1,0,0,0,2,0V20.529a1,1,0,0,0-2,0Z" />
    </svg>
  );
};

IconGetPaid.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

IconGetPaid.propTypes = { rootClassName: string, className: string };

export default IconGetPaid;
