import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';

import { NamedLink } from '../../components';

import css from './SectionReviews.module.css';

import oneImage from './images/one.jpg';
import twoImage from './images/two.jpg';
import threeImage from './images/three.jpg';

class ReviewImage extends Component {
  render() {
    const { alt, ...rest } = this.props;
    return <img alt={alt} {...rest} />;
  }
}
const LazyImage = lazyLoadWithDimensions(ReviewImage);

const reviewLink = (name, image, listingId) => {
  const nameText = <span className={css.reviewName}>{name}</span>;
  return (
    <NamedLink name="ListingPageCanonical" params={{ id: listingId }} className={css.review}>
      <div className={css.imageWrapper}>
        <div className={css.aspectWrapper}>
          <LazyImage src={image} alt={name} className={css.reviewImage} />
        </div>
      </div>
      <div className={css.linkText}>
        <FormattedMessage
          id="SectionReviews.listingsInReview"
          values={{ review: nameText }}
        />
      </div>
    </NamedLink>
  );
};

const SectionReviews = props => {
  const { rootClassName, className } = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div className={css.title}>
        <FormattedMessage id="SectionReviews.title" />
      </div>
      <div className={css.reviews}>
        {reviewLink(
          '',
          oneImage,
          '60a56abb-86cd-41f6-9047-789d52109c87'
        )}
        {reviewLink(
          '',
          twoImage,
          '60a7ac7f-5de6-4f08-8605-6783ccf7be87'
        )}
        {reviewLink(
          '',
          threeImage,
          '60ba04ec-f605-49c1-b735-8fb11108b006'
        )}
      </div>
    </div>
  );
};

SectionReviews.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

SectionReviews.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionReviews;
