import React from 'react';
import { FormattedMessage } from 'react-intl/dist/react-intl';
import AddressLinkMaybe from './AddressLinkMaybe';

import css from './TransactionPanel.module.css';

// Functional component as a helper to build detail card headings
const DetailCardHeadingsMaybe = props => {
  const {
    showDetailCardHeadings,
    listingTitle,
    subTitle,
    location,
    geolocation,
    showAddress,
    providerEmail,
    providerPhoneNumber,
  } = props;

  return showDetailCardHeadings ? (
    <div className={css.detailCardHeadings}>
      <h2 className={css.detailCardTitle}>{listingTitle}</h2>
      <p className={css.detailCardSubtitle}>
        <AddressLinkMaybe location={location} geolocation={geolocation} showAddress={showAddress} />
      </p>

      {(providerEmail || providerPhoneNumber) && (
        <div>
          <p className={css.detailCardSubtitle}>
            <strong><FormattedMessage id="TransactionPanel.providerContacts" /></strong>
          </p>
          {providerEmail && (
            <p className={css.address}>
              <a href={`mailto:${providerEmail}`}>{providerEmail}</a>
            </p>
          )}
          {providerPhoneNumber && (
            <p className={css.address}>
              <a href={`tel:${providerPhoneNumber}`}>{providerPhoneNumber}</a>
            </p>
          )}
        </div>
      )}
    </div>
  ) : null;
};

export default DetailCardHeadingsMaybe;
