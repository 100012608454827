import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconOfferRun.module.css';

const IconOfferRun = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg
      className={classes}
      width="100"
      height="100"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M33.662,23.01a2.429,2.429,0,0,0-1.624-2.289l-5.946-2.174H21.908l-5.946,2.174a2.481,2.481,0,0,0-1.624,2.43v.72H33.662Z"
        fill="#0099e2"
      />
      <path
        d="M23.911,16.452a3.846,3.846,0,0,1-2-.573v2.668a2.092,2.092,0,0,0,4.184,0V15.791a3.862,3.862,0,0,1-2.164.661Z"
        fill="#fbc5b1"
      />
      <rect height="15.132" fill="#3a3c40" width="28.485" x="9.757" y="23.514" />
      <path
        d="M9.757,38.645H38.243a0,0,0,0,1,0,0v.972a1.572,1.572,0,0,1-1.572,1.572H11.329a1.572,1.572,0,0,1-1.572-1.572v-.972A0,0,0,0,1,9.757,38.645Z"
        fill="#484a50"
      />
      <circle cx="23.919" cy="30.544" r="2.173" fill="#ffffff" />
      <polygon
        points="47.25 47.25 0.75 47.25 0.706 41.189 47.206 41.189 47.25 47.25"
        fill="#dfe0e2"
      />
      <path
        d="M27,8.334a3.837,3.837,0,0,1-3.292.872,6.287,6.287,0,0,1-2.442-.855,6.655,6.655,0,0,1-.942,1.573c-.3.386-.6.737-.6.737v1.9a4.191,4.191,0,0,0,4.191,4.191h.017a4.192,4.192,0,0,0,4.191-4.191v-1.9S27.733,7.815,27,8.334Z"
        fill="#fcd3c4"
      />
      <path
        d="M19.72,10.661A5.156,5.156,0,0,1,17.455,5.5c.576-2.8,3.689-3.163,5.51-3.163,2.454,0,3.388.71,4.633.958A2.2,2.2,0,0,0,29.422,2.5,6.083,6.083,0,0,1,29.971,8.7a6.562,6.562,0,0,1-1.852,1.964S27.733,7.978,27,8.5a3.839,3.839,0,0,1-3.292.871,6.277,6.277,0,0,1-2.442-.854,6.708,6.708,0,0,1-.942,1.572,2.5,2.5,0,0,1-.6.574Z"
        fill="#484a50"
      />
      <path
        d="M7.084,4.853a3.92,3.92,0,0,0-3.92,3.92c0,2.356,1.51,3.149,1.942,3.9a3.471,3.471,0,0,1,.546,1.656v.752a.465.465,0,0,0,.465.465H8.051a.466.466,0,0,0,.466-.465v-.752a3.471,3.471,0,0,1,.546-1.656c.432-.754,1.942-1.547,1.942-3.9A3.92,3.92,0,0,0,7.084,4.853Z"
        fill="#ffd36c"
      />
      <path
        d="M7.092,3.411a.5.5,0,0,1-.5-.5V2.334a.5.5,0,0,1,1,0v.577A.5.5,0,0,1,7.092,3.411Z"
        fill="#ffd36c"
      />
      <path
        d="M12.782,8.27a.5.5,0,0,1-.086-.992l.651-.115a.5.5,0,0,1,.174.984l-.651.115A.533.533,0,0,1,12.782,8.27Z"
        fill="#ffd36c"
      />
      <path
        d="M10.84,4.805a.5.5,0,0,1-.382-.821l.39-.465a.5.5,0,0,1,.766.643l-.39.465A.5.5,0,0,1,10.84,4.805Z"
        fill="#ffd36c"
      />
      <path
        d="M12.668,12.515a.5.5,0,0,1-.249-.066l-.562-.325a.5.5,0,0,1,.5-.866l.562.324a.5.5,0,0,1-.251.933Z"
        fill="#ffd36c"
      />
      <path
        d="M1.4,8.27a.533.533,0,0,1-.088-.008L.663,8.147a.5.5,0,0,1,.174-.984l.651.115A.5.5,0,0,1,1.4,8.27Z"
        fill="#ffd36c"
      />
      <path
        d="M3.343,4.805a.5.5,0,0,1-.383-.178l-.39-.465a.5.5,0,0,1,.767-.643l.389.465a.5.5,0,0,1-.383.821Z"
        fill="#ffd36c"
      />
      <path
        d="M1.515,12.515a.5.5,0,0,1-.25-.933l.562-.324a.5.5,0,0,1,.5.866l-.562.325A.5.5,0,0,1,1.515,12.515Z"
        fill="#ffd36c"
      />
      <path
        d="M8.8,13.206H5.373a3.185,3.185,0,0,1,.274,1H8.522A3.185,3.185,0,0,1,8.8,13.206Z"
        fill="#a9abb1"
      />
      <path
        d="M6.56,11.036a.376.376,0,0,1-.314-.58L6.933,9.4H6.292A.376.376,0,0,1,6,8.8l1.233-1.58a.375.375,0,0,1,.592.461l-.761.975h.564a.375.375,0,0,1,.314.579L6.875,10.866A.376.376,0,0,1,6.56,11.036Z"
        fill="#ffffff"
      />
    </svg>
  );
};

IconOfferRun.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

IconOfferRun.propTypes = { rootClassName: string, className: string };

export default IconOfferRun;
