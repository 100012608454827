import { types as sdkTypes } from './util/sdkLoader';

const { LatLng, LatLngBounds } = sdkTypes;

// An array of locations to show in the LocationAutocompleteInput when
// the input is in focus but the user hasn't typed in any search yet.
//
// Each item in the array should be an object with a unique `id` (String) and a
// `predictionPlace` (util.types.place) properties.
const defaultLocations = [
  {
    id: 'default-allruns',
    predictionPlace: {
      address: 'Zürich, Switzerland',
      bounds: new LatLngBounds(
        new LatLng(47.694749, 8.984951),
        new LatLng(47.159442, 8.357778)
      ),
    },
  },
  {
    id: 'default-allruns',
    predictionPlace: {
      address: 'Bern, Switzerland',
      bounds: new LatLngBounds(
        new LatLng(47.345320, 8.455076),
        new LatLng(46.326520, 6.861499)
      ),
    },
  },
  {
    id: 'default-allruns',
    predictionPlace: {
      address: 'World',
      bounds: new LatLngBounds(
        new LatLng(85.051129, 158.56657411),
        new LatLng(-85.051129, -164.89432898)
      ),
    },
  },
  {
    id: 'default-europe',
    predictionPlace: {
      address: 'Europe',
      bounds: new LatLngBounds(
        new LatLng(68.05640647, 36.73521153),
        new LatLng(35.87470752, -10.70219988)
      ),
    },
  },
  {
    id: 'default-switzerland',
    predictionPlace: {
      address: 'Switzerland',
      bounds: new LatLngBounds(
        new LatLng(47.808454, 10.492063),
        new LatLng(45.818077, 5.955994)
      ),
    },
  },
  {
    id: 'default-austria',
    predictionPlace: {
      address: 'Austria',
      bounds: new LatLngBounds(
        new LatLng(49.020525, 17.160761),
        new LatLng(46.372309, 9.5307406)
      ),
    },
  },
  {
    id: 'default-germany',
    predictionPlace: {
      address: 'Germany',
      bounds: new LatLngBounds(
        new LatLng(55.44769514, 16.09526528),
        new LatLng(47.66570411, 4.81288173)
      ),
    },
  },
  {
    id: 'default-singapore',
    predictionPlace: {
      address: 'Singapore',
      bounds: new LatLngBounds(
        new LatLng(1.477876, 104.106446),
        new LatLng(1.098564, 103.491212)
      ),
    },
  },
  {
    id: 'default-spain',
    predictionPlace: {
      address: 'Spain',
      bounds: new LatLngBounds(
        new LatLng(43.862351, 4.427455),
        new LatLng(27.549342, -18.260586)
      ),
    },
  },
  {
    id: 'default-sweden',
    predictionPlace: {
      address: 'Sweden',
      bounds: new LatLngBounds(
        new LatLng(69.059926, 24.193368),
        new LatLng(55.280224, 10.838366)
      ),
    },
  },
  {
    id: 'default-thailand',
    predictionPlace: {
      address: 'Thailand',
      bounds: new LatLngBounds(
        new LatLng(20.464776, 105.636582),
        new LatLng(5.612642, 97.343589)
      ),
    },
  },
  {
    id: 'default-usa',
    predictionPlace: {
      address: 'United States',
      bounds: new LatLngBounds(
        new LatLng(71.4202919997506, -66.8847646185949),
        new LatLng(18.8163608007951, -179.9)
      ),
    },
  },
];
export default defaultLocations;
