import React, { useState } from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { Modal, PrimaryButton, SecondaryButton } from '..';

import css from './TransactionPanel.module.css';

const CancelButtonsMaybe = props => {
  const {
    showButtons,
    className,
    rootClassName,
    cancelByCustomerInProgress,
    cancelBuyError,
    onCancelBuy,
    onManageDisableScrolling,
    isAnnul,
    isProvider,
  } = props;

  const [isOpen, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const buttonsDisabled = cancelByCustomerInProgress;

  const cancelErrorMessage = cancelBuyError ? (
    <p className={css.actionError}>
      <FormattedMessage id="TransactionPanel.cancelBuyFailed" />
    </p>
  ) : null;

  const classes = classNames(rootClassName || css.actionButtons, className);

  return showButtons ? (
    <div className={classes}>
      <div className={css.actionButtonWrapper}>
        <div className={css.cancelButtonsWrapper}>
          <SecondaryButton
            className={css.cancel}
            inProgress={cancelByCustomerInProgress}
            disabled={buttonsDisabled}
            onClick={handleOpen}
          >
            <FormattedMessage id="TransactionPanel.cancelButton" />
          </SecondaryButton>

          {isAnnul && (
            <div className={css.cancelNotice}>
              <FormattedMessage id="TransactionPanel.cancelNotice" />
            </div>
          )}

          <div className={css.cancelActionErrors}>{cancelErrorMessage}</div>
        </div>
      </div>
      <Modal
        id="CancelTransactionModal"
        containerClassName={css.modalContainer}
        isOpen={isOpen}
        onClose={handleClose}
        onManageDisableScrolling={onManageDisableScrolling}
        usePortal
      >
        <h1 className={css.modalTitle}>
          <FormattedMessage
            id={
              isProvider
                ? 'TransactionPanel.cancelModalTitleProvider'
                : 'TransactionPanel.cancelModalTitleCustomer'
            }
          />
        </h1>
        <div className={css.confirmationModalButtons}>
          <PrimaryButton
            className={css.modalButton}
            inProgress={cancelByCustomerInProgress}
            disabled={buttonsDisabled}
            onClick={onCancelBuy}
          >
            <FormattedMessage id="TransactionPanel.cancelButton" />
          </PrimaryButton>
          <SecondaryButton
            className={css.modalButton}
            disabled={buttonsDisabled}
            onClick={handleClose}
          >
            <FormattedMessage id="TransactionPanel.dismiss" />
          </SecondaryButton>
        </div>

        {isAnnul && (
          <div className={css.cancelNotice}>
            <FormattedMessage id="TransactionPanel.cancelNotice" />
          </div>
        )}
      </Modal>
    </div>
  ) : null;
};

export default CancelButtonsMaybe;
