import React from 'react';
import { bool, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { IconDrink, IconGetPaid, IconOfferRun, IconRun, OwnListingLink } from '../../components';
import { Controller, Scene } from 'react-scrollmagic';

import css from './SectionHowToOffer.module.css';

const SectionHowToOffer = props => {
  const { rootClassName, className } = props;

  const classes = classNames(rootClassName || css.root, className);
  return (
    <div className={classes}>
      <div className={css.title}>
        <FormattedMessage id="SectionHowToOffer.titleLineOne" />
        <br />
        <FormattedMessage id="SectionHowToOffer.titleLineTwo" />
      </div>
      <div className={css.steps}>
        <div className={css.step}>
          <Controller>
            <Scene duration={0} classToggle={css.iconVisible} offset={-200}>
              <div className={css.iconInvisible}>
                <IconOfferRun />
              </div>
            </Scene>
          </Controller>
          <h2 className={css.stepTitle}>
            <FormattedMessage id="SectionHowToOffer.subtitle1" />
          </h2>
          <p>
            <FormattedMessage id="SectionHowToOffer.subtext1" />
          </p>
        </div>
        <div className={css.step}>
          <Controller>
            <Scene duration={0} classToggle={css.iconVisible} offset={-200}>
              <div className={css.iconInvisible}>
                <IconRun />
              </div>
            </Scene>
          </Controller>
          <h2 className={css.stepTitle}>
            <FormattedMessage id="SectionHowToOffer.subtitle2" />
          </h2>
          <p>
            <FormattedMessage id="SectionHowToOffer.subtext2" />
          </p>
        </div>
        <div className={css.step}>
          <Controller>
            <Scene duration={0} classToggle={css.iconVisible} offset={-200}>
              <div className={css.iconInvisible}>
                <IconGetPaid />
              </div>
            </Scene>
          </Controller>
          <h2 className={css.stepTitle}>
            <FormattedMessage id="SectionHowToOffer.subtitle3" />
          </h2>
          <p>
            <FormattedMessage id="SectionHowToOffer.subtext3" />{' '}
            <span className={css.getDrink} tabIndex="1">
              <FormattedMessage id="SectionHowToOffer.getDrink" />
            </span>
            <span className={css.haveDrink}>
              <IconDrink />
              <br />
              <span className={css.viva}>
                <FormattedMessage id="SectionHowToOffer.viva" />
              </span>
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};

SectionHowToOffer.defaultProps = {
  rootClassName: null,
  className: null,
};

SectionHowToOffer.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionHowToOffer;
