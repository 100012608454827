import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { TopbarContainer } from '../../containers';
import {
  Page,
  LayoutSideNavigation,
  LayoutWrapperMain,
  LayoutWrapperSideNav,
  LayoutWrapperTopbar,
  LayoutWrapperFooter,
  Footer,
  ExternalLink,
} from '../../components';
import config from '../../config';

import css from './ContactPage.module.css';
import image from './contact.jpg';

const ContactPageComponent = props => {
  const { scrollingDisabled, intl } = props;

  const tabs = [
    {
      text: intl.formatMessage({ id: 'AboutPage.aboutTabTitle' }),
      selected: false,
      linkProps: {
        name: 'AboutPage',
      },
    },
    {
      text: intl.formatMessage({ id: 'ContactPage.contactTabTitle' }),
      selected: true,
      linkProps: {
        name: 'ContactPage',
      },
    },
    {
      text: intl.formatMessage({ id: 'FAQPage.faqTabTitle' }),
      selected: false,
      linkProps: {
        name: 'FAQPage',
      },
    },
  ];
  const siteTitle = config.siteTitle;
  const schemaTitle = intl.formatMessage({ id: 'ContactPage.schemaTitle' }, { siteTitle });
  const schema = {
    '@context': 'http://schema.org',
    '@type': 'WebPage',
    name: schemaTitle,
  };
  return (
    <Page title={schemaTitle} scrollingDisabled={scrollingDisabled} schema={schema}>
      <LayoutSideNavigation>
        <LayoutWrapperTopbar>
          <TopbarContainer currentPage="ContactPage" />
        </LayoutWrapperTopbar>
        <LayoutWrapperSideNav tabs={tabs} />
        <LayoutWrapperMain>
          <div className={css.headingContainer}>
            <div className={css.heading}>
              <FormattedMessage id="ContactPage.heading" />
            </div>
            <div className={css.subHeading}>
              <FormattedMessage id="ContactPage.subHeading" />
            </div>
          </div>
          <div className={css.content}>
            <p>
              In case you have any questions or comments, please drop us an old-fashioned email. We
              are happy to answer all your enquiries. No fancy forum, live chat, WhatsApp, smart ass
              assistant, or chat bot available here.
            </p>
            <h2 id="contact" className={css.subtitle}>
              Please send your email to{' '}
              <ExternalLink href="mailto:hello@shareyourrun.com">
                hello@shareyourrun.com
              </ExternalLink>
            </h2>
          </div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSideNavigation>
    </Page>
  );
};

const { bool } = PropTypes;

ContactPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  return {
    scrollingDisabled: isScrollingDisabled(state),
  };
};

const ContactPage = compose(
  connect(mapStateToProps),
  injectIntl
)(ContactPageComponent);

export default ContactPage;
