import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconMission.module.css';

const IconMission = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg
      className={classes}
      width="75"
      height="75"
      viewBox="0 0 128 128"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill="#ffd36c"
        d="M39.8,90.2c5.6,5.6,5.6,14.8,0,20.4s-22.1,12.9-27.7,7.3s1.7-22.1,7.3-27.7C25,84.6,34.1,84.6,39.8,90.2z"
      />
      <path
        fill="#fdb924"
        d="M23.1,87.5c0,0.2,0,0.4,0,0.6c0,10.4,8.5,18.9,18.9,18.9c0.1,0,0.3,0,0.4,0c2.8-5.4,1.9-12.2-2.7-16.8  C35.3,85.7,28.5,84.8,23.1,87.5z"
      />
      <path
        fill="#221b57"
        d="M69.2,58.8c17.1,17.1,20.5,41.4,4.3,57.6l-7.7,7.7c-16.2,16.2-5-22.7-22.1-39.8s-56.1-5.8-39.8-22.1l7.7-7.7  C27.8,38.3,52.1,41.7,69.2,58.8z"
      />
      <path
        fill="#0099e2"
        d="M125.8,2.2c5.2,5.2,2.9,38.2-29,70.2c-20.8,20.7-33.9,28-33.9,28c-13.9,9.4-20.6,3-29.4-5.8  s-15.3-15.5-5.8-29.4c0,0,7.4-13.2,28.1-34C87.7-0.7,120.6-3.1,125.8,2.2z"
      />
      <path
        fill="#007db8"
        d="M68.4,95.9c-14.5,9.7-21.5,3.1-30.6-6c-9.2-9.2-15.9-16.1-6-30.6c0,0,0.5-0.8,1.5-2.4c-3.9,5.3-5.6,8.3-5.6,8.3  c-9.5,13.9-3,20.6,5.8,29.4s15.5,15.1,29.4,5.8c0,0,6.2-3.5,16.9-12.4C72.5,93.6,68.4,95.9,68.4,95.9z"
      />
      <path
        fill="#f5f5f8"
        d="M92.3,35.7c4.9,4.9,4.9,12.8,0,17.7s-12.8,4.9-17.7,0c-4.9-4.9-4.9-12.8,0-17.7C79.5,30.8,87.5,30.8,92.3,35.7z  "
      />
      <path
        fill="#221b57"
        d="M63.4,66.7c2.5,2.5-2.3,11.4-14.5,23.6s-19.6,15.5-22.1,12.9c-2.5-2.5,0.7-9.9,12.9-22.1  C52,68.9,60.8,64.1,63.4,66.7z"
      />
      <path
        fill="#dfe0e2"
        d="M83.5,35c2.5,0,4.9,1,6.7,2.8C92,39.6,93,42,93,44.5s-1,4.9-2.8,6.7C88.4,53,86,54,83.5,54s-4.9-1-6.7-2.8  C75,49.4,74,47,74,44.5s1-4.9,2.8-6.7C78.6,36,81,35,83.5,35 M83.5,32c-3.2,0-6.4,1.2-8.8,3.7c-4.9,4.9-4.9,12.8,0,17.7  c2.4,2.4,5.6,3.7,8.8,3.7c3.2,0,6.4-1.2,8.8-3.7c4.9-4.9,4.9-12.8,0-17.7C89.9,33.2,86.7,32,83.5,32L83.5,32z"
      />
      <path
        fill="#221b57"
        d="M95.2,4.7c0.9,6.7,4.5,13.8,10.4,19.7c5.2,5.1,11.2,8.5,17.1,9.9c6.6-16.4,6.1-29,3.1-32.1  C122.9-0.8,110.9-1.3,95.2,4.7z"
      />
    </svg>
  );
};

IconMission.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

IconMission.propTypes = { rootClassName: string, className: string };

export default IconMission;
