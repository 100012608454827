import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconVision.module.css';

const IconVision = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg
      className={classes}
      width="75"
      height="75"
      viewBox="0 0 33 33"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.76,12.588c-0.79-0.936-2.26-0.846-2.26-0.846s-1.469-0.09-2.259,0.846l-1.011,4.254l0.991,4.021   c0.354,0.914,1.242,1.563,2.278,1.563c1.037,0,1.925-0.648,2.279-1.563l0.99-3.78L18.76,12.588z"
        fill="#0099e2"
      />
      <path
        d="M13.594,13.363c0.004-0.478,0.09-3.612,0.095-4.352c0.016-2.465-4.624-3.988-6.648-1.511   c-0.318,0.39-1.764,2.882-2.154,3.592C7.588,9.977,11.871,11.701,13.594,13.363z"
        fill="#0099e2"
      />
      <path
        d="M19.406,13.363c-0.002-0.478-0.089-3.612-0.094-4.352c-0.016-2.465,4.624-3.988,6.648-1.511   c0.318,0.39,1.764,2.882,2.154,3.592C25.413,9.977,21.131,11.701,19.406,13.363z"
        fill="#0099e2"
      />
      <path
        d="M18.779,20.862c0.485,3.332,3.36,5.899,6.824,5.899c3.803,0,6.896-3.095,6.896-6.897   c0-1.131-0.273-2.199-0.758-3.142c0,0-2.093-4.685-3.297-5.923c-2.802-2.883-9.051-0.548-9.686,1.788L18.779,20.862z"
        fill="#221b57"
      />
      <path
        d="M14.241,12.588c-0.635-2.336-6.884-4.671-9.686-1.788c-1.205,1.238-3.298,5.923-3.298,5.923l0,0   C0.773,17.665,0.5,18.733,0.5,19.864c0,3.803,3.095,6.897,6.897,6.897c3.464,0,6.339-2.567,6.824-5.899L14.241,12.588z"
        fill="#221b57"
      />
      <path
        d="M7.397,24.554c-2.586,0-4.689-2.104-4.689-4.689s2.104-4.689,4.689-4.689c2.585,0,4.689,2.104,4.689,4.689   S9.982,24.554,7.397,24.554z"
        fill="#dfe0e2"
      />
      <circle cx="16.5" cy="19.98" fill="#E6E7E8" r="1.312" />
      <path
        d="M25.604,24.554c-2.585,0-4.689-2.104-4.689-4.689s2.104-4.689,4.689-4.689s4.689,2.104,4.689,4.689   S28.188,24.554,25.604,24.554z"
        fill="#dfe0e2"
      />
      <path
        d="M7.071,22.259H6.923c-0.972,0-1.762-0.79-1.762-1.762v-0.148c0-0.382-0.31-0.691-0.691-0.691   c-0.381,0-0.69,0.31-0.69,0.691v0.297c0,0.047,0.005,0.092,0.014,0.136c0.136,1.507,1.339,2.71,2.846,2.846   c0.044,0.009,0.089,0.014,0.136,0.014h0.297c0.382,0,0.691-0.31,0.691-0.69C7.763,22.568,7.453,22.259,7.071,22.259z"
        fill="#f5f5f8"
      />
      <path
        d="M25.278,22.259H25.13c-0.972,0-1.762-0.79-1.762-1.762v-0.148c0-0.382-0.31-0.691-0.691-0.691   c-0.381,0-0.69,0.31-0.69,0.691v0.297c0,0.047,0.005,0.092,0.013,0.136c0.137,1.507,1.34,2.71,2.847,2.846   c0.044,0.009,0.089,0.014,0.135,0.014h0.298c0.382,0,0.691-0.31,0.691-0.69C25.97,22.568,25.66,22.259,25.278,22.259z"
        fill="#f5f5f8"
      />
    </svg>
  );
};

IconVision.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

IconVision.propTypes = { rootClassName: string, className: string };

export default IconVision;
