import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './TermsOfService.module.css';

const TermsOfService = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  // prettier-ignore
  return (
    <div className={classes}>
      <p className={css.lastUpdated}>Last updated: May 4<sup>th</sup>, 2021</p>
      <p>Please read these Terms of service carefully before using Our Service.</p>
      <h2>1. Interpretation and definitions</h2>
      <h3>1.1. Interpretation</h3>
      <p>The words of which the initial letter is capitalized have meanings defined under the following Terms of service. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.</p>
      <h3>1.2. Definitions</h3>
      <p>For the purposes of these Terms of service:</p>
      <p>
      <div className={css.list}>
         <strong>Account</strong> means a unique account created for You to access our Service or parts of our Service.
      </div>
      <div className={css.list}>
         <strong>Booking</strong> means a request by You to purchase a Run from Us.
      </div>
      <div className={css.list}>
         <strong>Company</strong> (referred to as either &quot;the Company&quot;, &quot;We&quot;, &quot;Us&quot; or &quot;Our&quot; in these Terms of service) refers to SHARE YOUR RUN GmbH, Albisriederstrasse 334, 8047 Zürich, Switzerland, UID CHE-421.948.970.
      </div>
      <div className={css.list}>
         <strong>Content</strong> refers to content such as text, images, or other information that can be posted, uploaded, linked to or otherwise made available by You, regardless of the form of that content.
      </div>
      <div className={css.list}>
         <strong>Feedback</strong> means feedback, innovations or suggestions sent by You regarding the attributes, performance or features of our Service.
      </div>
      <div className={css.list}>
         <strong>Guide</strong> refers to a user, offering a Run through Our Service.
      </div>
      <div className={css.list}>
         <strong>Privacy policy</strong> refers to Our Privacy policy, accessible from <a href="https://www.shareyourrun.com/privacy-policy" rel="external nofollow noopener" target="_blank">https://www.shareyourrun.com/privacy-policy</a>
      </div>
      <div className={css.list}>
         <strong>Promotions</strong> refer to contests, sweepstakes or other promotions offered through the Service.
      </div>
      <div className={css.list}>
         <strong>Runs</strong> refer to the running experiences offered for sale on the Service.
      </div>
      <div className={css.list}>
         <strong>Service</strong> refers to SHARE YOUR RUN, accessible from <a href="https://www.shareyourrun.com" rel="external nofollow noopener" target="_blank">https://www.shareyourrun.com</a>
      </div>
      <div className={css.list}>
         <strong>Terms of service</strong> (also referred as &quot;Terms&quot;) mean these Terms of service that form the entire agreement between You and the Company regarding the use of the Service.
      </div>
      <div className={css.list}>
         <strong>Third-party Social Media Service</strong> means any services or content (including data, information, products or services) provided by a third-party that may be displayed, included or made available by the Service.
      </div>
      <div className={css.list}>
         <strong>You</strong> means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.
      </div>
      </p>
      <h2>2. Acknowledgment</h2>
      <p>These are the Terms of service governing the use of this Service and the agreement that operates between You and the Company. These Terms of service set out the rights and obligations of all users regarding the use of the Service.</p>
      <p>Your access to and use of the Service is conditioned on Your acceptance of and compliance with these Terms of service. These Terms of service apply to all users and others who access or use the Service.</p>
      <p>By accessing or using the Service You agree to be bound by these Terms of service. If You disagree with any part of these Terms of service then You may not access the Service.</p>
      <p>You represent that you are over the age of 18. The Company does not permit those under 18 to use the Service.</p>
      <p>Your access to and use of the Service is also conditioned on Your acceptance of and compliance with the <a href="/privacy-policy">Privacy policy</a> of the Company. Our Privacy policy describes Our policies and procedures on the collection, use and disclosure of Your personal information when You use Our Service and tells You about Your privacy rights and how the law protects You. Please read Our Privacy policy carefully before using Our Service.</p>
      <p>We only offer intermediary Services between registered users. We are therefore not a contractual party in the Bookings for Runs that are made between registered users. In particular, We bear no responsibility for any damages that may arise as a result of entering into or fulfilling these Bookings.</p>
      <h2>3. Placing bookings for runs</h2>
      <h3>3.1. Booking process</h3>
      <p>By placing a Booking for Runs through the Service, You warrant that You are legally capable of entering into binding contracts.</p>
      <p>Through the Service, registered users can conclude their Bookings for Runs with one another. Only the relevant registered users are granted any authorisations or obligations as a result of these Bookings. Only the relevant registered users are responsible for fulfilling these Bookings.</p>
      <p>The content of the Booking can be negotiated autonomously by the registered users. However, when concluding a Booking the Service, the following content will automatically be part of the relevant Booking:</p>
      <p>
        <div className={css.list}>Validity of the Booking: The Booking comes into effect as soon as the users books a Run from a Guide and the Booking is confirmed by the Guide (within 72 hours or at least 12 hours before the start of the Run - whichever is earlier).</div>
        <div className={css.list}>Obligations of the Guides: Guides are required to provide their services in accordance with legal guidelines and with appropriate care.</div>
        <div className={css.list}><strong>Free cancellation by the Booking user:</strong> You have the right to cancel the booked Run free of charge up to 72 hours before the Run is due to be provided. In such a case, the total price of the run will be reimbursed to the Booking user.</div>
        <div className={css.list}><strong>Fee-based cancellation by the Booking user:</strong> If you cancel the booked Run less than 72 hours before the service is due to be provided, you are still obliged to pay the total price of the Run.</div>
        <div className={css.list}><strong>Free cancellation by the Guide:</strong> Guides have the right to cancel the booked Run free of charge anytime before the Run is due to be provided.  In such a case, the total price of the run will be reimbursed to the Booking user. However, the Guide may only cancel for important reasons such as inability to attend due to an accident, illness, adverse weather conditions, or comparable reasons. Before cancelling, the Guide is encouraged to contact the Booking user in order to find a solution based on mutual understanding.</div>
      </p>
      <h3>3.2. Your information</h3>
      <p>If You wish to place a Booking for Runs available on the Service, You may be asked to supply certain information relevant to Your Booking including, without limitation, Your name, Your email, Your phone number, Your credit card number, the expiration date of Your credit card, and Your billing address.</p>
      <p>You represent and warrant that: (i) You have the legal right to use any credit or debit card(s) or other payment method(s) in connection with any Booking; and that (ii) the information You supply to us is true, correct and complete.</p>
      <p>By submitting such information, You grant us the right to provide the information to payment processing third parties for purposes of facilitating the completion of Your Booking.</p>
      <h3>3.3. Availability, errors and inaccuracies</h3>
      <p>We and the Guides are constantly updating Our offerings of Runs on the Service. The Runs available on Our Service may be mispriced, described inaccurately, or unavailable, and We may experience delays in updating information regarding our Runs on the Service.</p>
      <p>We cannot and do not guarantee the accuracy or completeness of any information, including prices, images, specifications, availability, and services. We reserve the right to change or update information and to correct errors, inaccuracies, or omissions at any time without prior notice.</p>
      <h3>3.4. Prices policy</h3>
      <p>The Company and the Guides reserve the right to revise prices of Runs at any time prior to accepting a Booking.</p>
      <h3>3.5. Payments</h3>
      <p>All Runs purchased are subject to a one-time payment. Payment can be made through credit cards.</p>
      <p>Credit cards are subject to validation checks and authorization by Your card issuer. If we do not receive the required authorization, We will not be liable for any delay or non-delivery of Your Booking.</p>
      <h2>4. Promotions</h2>
      <p>Any Promotions made available through the Service may be governed by rules that are separate from these Terms.</p>
      <p>If You participate in any Promotions, please review the applicable rules as well as our Privacy policy. If the rules for a Promotion conflict with these Terms, the Promotion rules will apply.</p>
      <h2>5. User accounts</h2>
      <p>When You create an Account with Us, You must provide Us information that is accurate, complete, and current at all times. Failure to do so constitutes a breach of the Terms, which may result in immediate termination of Your Account on Our Service.</p>
      <p>You are responsible for safeguarding the password that You use to access the Service and for any activities or actions under Your password, whether Your password is with Our Service or a Third-Party Social Media Service.</p>
      <p>You agree not to disclose Your password to any third party. You must notify Us immediately upon becoming aware of any breach of security or unauthorized use of Your Account.</p>
      <p>You may not use as a username the name of another person or entity or that is not lawfully available for use, a name or trademark that is subject to any rights of another person or entity other than You without appropriate authorization, or a name that is otherwise offensive, vulgar or obscene.</p>
      <h2>6. Content</h2>
      <h3>6.1. Your right to post content</h3>
      <p>Our Service allows You (as a Guide) to post Content. You are responsible for the Content that You post to the Service, including its legality, reliability, and appropriateness.</p>
      <p>By posting Content to the Service, You grant Us the non-exklusive, perpetual, irrevocable, royalty free, worldwide right and license to use, modify, publicly perform, publicly display, reproduce, and distribute such Content on and through the Service - or any Third-party Social Media Service. You retain any and all of Your rights to any Content You submit, post or display on or through the Service and You are responsible for protecting those rights. You agree that this license includes the right for Us to make Your Content available to other users of the Service, who may also use Your Content subject to these Terms.</p>
      <p>You represent and warrant that: (i) the Content is Yours (You own it) or You have the right to use it and grant Us the rights and license as provided in these Terms, and (ii) the posting of Your Content on or through the Service does not violate the privacy rights, publicity rights, copyrights, contract rights or any other rights of any person.</p>
      <h3>6.2. Content restrictions</h3>
      <p>The Company is not responsible for the content of the Service's users. You expressly understand and agree that You are solely responsible for the Content and for all activity that occurs under your Account, whether done so by You or any third person using Your Account.</p>
      <p>You may not transmit any Content that is unlawful, offensive, upsetting, intended to disgust, threatening, libelous, defamatory, obscene or otherwise objectionable. Examples of such objectionable Content include, but are not limited to, the following:</p>
      <p>
        <div className={css.list}>Unlawful or promoting unlawful activity.</div>
        <div className={css.list}>Defamatory, discriminatory, or mean-spirited content, including references or commentary about religion, race, sexual orientation, gender, national/ethnic origin, or other targeted groups.</div>
        <div className={css.list}>Spam, machine – or randomly – generated, constituting unauthorized or unsolicited advertising, chain letters, any other form of unauthorized solicitation, or any form of lottery or gambling.</div>
        <div className={css.list}>Containing or installing any viruses, worms, malware, trojan horses, or other content that is designed or intended to disrupt, damage, or limit the functioning of any software, hardware or telecommunications equipment or to damage or obtain unauthorized access to any data or other information of a third person.</div>
        <div className={css.list}>Infringing on any proprietary rights of any party, including patent, trademark, trade secret, copyright, right of publicity or other rights.</div>
        <div className={css.list}>Impersonating any person or entity including the Company and its employees or representatives.</div>
        <div className={css.list}>Violating the privacy of any third person.</div>
        <div className={css.list}>False information and features.</div>
      </p>
      <p>The Company reserves the right, but not the obligation, to, in its sole discretion, determine whether or not any Content is appropriate and complies with this Terms, refuse or remove this Content. The Company further reserves the right to make formatting and edits and change the manner any Content. The Company can also limit or revoke the use of the Service if You post such objectionable Content.
         As the Company cannot control all content posted by users and/or third parties on the Service, you agree to use the Service at your own risk. You understand that by using the Service You may be exposed to content that You may find offensive, indecent, incorrect or objectionable, and You agree that under no circumstances will the Company be liable in any way for any content, including any errors or omissions in any content, or any loss or damage of any kind incurred as a result of your use of any content.
      </p>
      <h3>6.3. Content backups</h3>
      <p>Although regular backups of Content are performed, the Company do not guarantee there will be no loss or corruption of data.</p>
      <p>Corrupt or invalid backup points may be caused by, without limitation, Content that is corrupted prior to being backed up or that changes during the time a backup is performed.</p>
      <p>The Company will provide support and attempt to troubleshoot any known or discovered issues that may affect the backups of Content. But You acknowledge that the Company has no liability related to the integrity of Content or the failure to successfully restore Content to a usable state.</p>
      <p>You agree to maintain a complete and accurate copy of any Content in a location independent of the Service.</p>
      <h2>7. Intellectual property infringement</h2>
      <p>We respect the intellectual property rights of others. It is Our policy to respond to any claim that Content posted on the Service infringes a copyright or other intellectual property infringement of any person.</p>
      <p>If You are a copyright owner, or authorized on behalf of one, and You believe that the copyrighted work has been copied in a way that constitutes copyright infringement that is taking place through the Service, You must submit Your notice in writing to the attention of our copyright agent via email at <a href="mailto:hello@shareyourrun.com">hello@shareyourrun.com</a> and include in Your notice a detailed description of the alleged infringement.</p>
      <p>You may be held accountable for damages (including costs and attorneys' fees) for misrepresenting that any Content is infringing Your copyright.</p>
      <h2>8. Intellectual property</h2>
      <p>The Service and its original content (excluding Content provided by You or other users), features and functionality are and will remain the exclusive property of the Company and its licensors.</p>
      <p>The Service is protected by copyright, trademark, and other laws of both Switzerland.</p>
      <p>Our trademarks and trade dress may not be used in connection with any product or service without the prior written consent of the Company.</p>
      <h2>9. Your feedback to us</h2>
      <p>You assign all rights, title and interest in any Feedback You provide the Company. If for any reason such assignment is ineffective, You agree to grant the Company a non-exclusive, perpetual, irrevocable, royalty free, worldwide right and license to use, reproduce, disclose, sub-license, distribute, modify and exploit such Feedback without restriction.</p>
      <h2>10. Links to other web sites</h2>
      <p>Our Service may contain links to third-party web sites or services that are not owned or controlled by the Company.</p>
      <p>The Company has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third party web sites or services. You further acknowledge and agree that the Company shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of or reliance on any such content, goods or services available on or through any such web sites or services.</p>
      <p>We strongly advise You to read the terms of service and privacy policies of any third-party web sites or services that You visit.</p>
      <h2>11. Termination</h2>
      <p>We may terminate or suspend Your Account immediately, without prior notice or liability, for any reason whatsoever, including without limitation if You breach these Terms of service.</p>
      <p>Upon termination, Your right to use the Service will cease immediately. If You wish to terminate Your Account, You may simply discontinue using the Service.</p>
      <h2>12. Limitation of liability</h2>
      <p>Notwithstanding any damages that You might incur, the entire liability of the Company and any of its suppliers under any provision of this Terms and Your exclusive remedy for all of the foregoing shall be limited to the amount actually paid by You through the Service or EUR 100.- if You haven't purchased anything through the Service.</p>
      <p>To the maximum extent permitted by applicable law, in no event shall the Company or its suppliers be liable for any special, incidental, indirect, or consequential damages whatsoever (including, but not limited to, damages for loss of profits, loss of data or other information, for business interruption, for personal injury, loss of privacy arising out of or in any way related to the use of or inability to use the Service, third-party software and/or third-party hardware used with the Service, or otherwise in connection with any provision of this Terms), even if the Company or any supplier has been advised of the possibility of such damages and even if the remedy fails of its essential purpose.</p>
      <h2>13. &quot;as is&quot; and &quot;as available&quot; disclaimer</h2>
      <p>The Service is provided to You &quot;as is&quot; and &quot;as available&quot; and with all faults and defects without warranty of any kind. To the maximum extent permitted under applicable law, the Company expressly disclaims all warranties, whether express, implied, statutory or otherwise, with respect to the Service, including all implied warranties of merchantability, fitness for a particular purpose, title and non-infringement, and warranties that may arise out of course of dealing, course of performance, usage or trade practice. Without limitation to the foregoing, the Company provides no warranty or undertaking, and makes no representation of any kind that the Service will meet Your requirements, achieve any intended results, be compatible or work with any other software, applications, systems or services, operate without interruption, meet any performance or reliability standards or be error free or that any errors or defects can or will be corrected.</p>
      <p>Without limiting the foregoing, neither the Company nor any of the company's provider makes any representation or warranty of any kind, express or implied: (i) as to the operation or availability of the Service, or the information, content, and materials or products included thereon; (ii) that the Service will be uninterrupted or error-free; (iii) as to the accuracy, reliability, or currency of any information or content provided through the Service; or (iv) that the Service, its servers, the content, or emails sent from or on behalf of the Company are free of viruses, scripts, trojan horses, worms, malware, timebombs or other harmful components.</p>
      <p>Some jurisdictions do not allow the exclusion of certain types of warranties or limitations on applicable statutory rights of a consumer, so some or all of the above exclusions and limitations may not apply to You. But in such a case the exclusions and limitations set forth in this section shall be applied to the greatest extent enforceable under applicable law.</p>
      <h2>14. Governing law</h2>
      <p>The agreements between You and Us, including these Terms, as well as all resulting rights and obligations, are subject to Swiss law, to the exclusion of the United Nations Convention on Contracts for the International Sale of Goods dated 11 April 1980 (SR 0.221.211.1).</p>
      <p>With the exception of mandatory places of jurisdiction, the ordinary courts in Zürich, in the Swiss canton of Zürich, will be solely responsible for resolving disagreements between You and Us.</p>
      <h2>15. Disputes resolution</h2>
      <p>If You have any concern or dispute about the Service, You agree to first try to resolve the dispute informally by contacting the Company.</p>
      <h2>16. Place of jurisdiction</h2>
      <p>The place of jurisdiciton is Zürich, Switzerland.</p>
      <h2>17. Severability and waiver</h2>
      <h3>17.1. Severability</h3>
      <p>If any provision of these Terms is held to be unenforceable or invalid, such provision will be changed and interpreted to accomplish the objectives of such provision to the greatest extent possible under applicable law and the remaining provisions will continue in full force and effect.</p>
      <h3>17.2. Waiver</h3>
      <p>Except as provided herein, the failure to exercise a right or to require performance of an obligation under this Terms shall not effect a party's ability to exercise such right or require such performance at any time thereafter nor shall be the waiver of a breach constitute a waiver of any subsequent breach.</p>
      <h2>18. Changes to these Terms of service</h2>
      <p>We reserve the right, at Our sole discretion, to modify or replace these Terms at any time. If a revision is material We will make reasonable efforts to provide at least 30 days' notice prior to any new terms taking effect. What constitutes a material change will be determined at Our sole discretion.</p>
      <p>By continuing to access or use Our Service after those revisions become effective, You agree to be bound by the revised terms. If You do not agree to the new terms, in whole or in part, You have to stop using the Service.</p>
      <h2>19. Contact us</h2>
      <p>If you have any questions about these Terms of service, You can contact us via email at <a href="mailto:hello@shareyourrun.com">hello@shareyourrun.com</a></p>
    </div>
  );
};

TermsOfService.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

TermsOfService.propTypes = {
  rootClassName: string,
  className: string,
};

export default TermsOfService;
