import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconFriendship.module.css';

const IconFriendship = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg
      className={classes}
      width="35"
      height="35"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect height="14.141" fill="#f5f5f8" width="3.587" x="38.307" y="14.273" />
      <rect height="14.141" fill="#f5f5f8" width="3.587" x="6.106" y="14.273" />
      <rect height="16.398" fill="#0099e2" width="5.361" x="41.889" y="13.147" />
      <rect height="16.398" fill="#0099e2" width="5.361" x="0.75" y="13.147" />
      <path
        d="M38.31,16.18l-.01,8.44v1.64l-2.88-.27a11.01,11.01,0,0,1-1.36,1.48,5.443,5.443,0,0,1-2.44,1.13,1.709,1.709,0,0,0-.33-2.03l-6.69-6.4-.04-.04-.38-.44c0-.01-.01-.02-.01-.03L21.8,16.88a4.512,4.512,0,0,1-1.01-1.33,5.115,5.115,0,0,1-.4-.92l2.73-.74,6.23-1.68,6.04,3.81Z"
        fill="#fbc5b1"
      />
      <path
        d="M31.29,26.57l-6.69-6.4-.04-.04-.3-.29s-.03-.05-.08-.15c0-.01-.01-.02-.01-.03L21.8,16.88a4.512,4.512,0,0,1-1.01-1.33A7.854,7.854,0,0,0,17.91,15a2.621,2.621,0,0,0-1.83.07L12.9,16.44H9.7V26.87h2.65a9.39,9.39,0,0,0,3.05,3.81,51.859,51.859,0,0,1,5.29,4.61,1.859,1.859,0,0,0,2.45.07.276.276,0,0,0,.09-.07,1.715,1.715,0,0,0,.09-2.15l.42.4a1.7,1.7,0,0,0,2.36-2.46l.51.45a1.794,1.794,0,0,0,2.67.19c.94-.91.36-2-.31-2.65a1.705,1.705,0,0,0,2.32-2.5ZM23.18,33l-.11-.16a1.493,1.493,0,0,1,.23.27Z"
        fill="#fcd3c4"
      />
      <path
        d="M38.31,16.18l-.01,8.44a13.384,13.384,0,0,1-3.21-.43c-1.03-.44-1.1-2.73-.59-3.66.69-1.25-1.09-2.72-1.09-2.72a6.584,6.584,0,0,1-2.72-.03,4.6,4.6,0,0,1-2.81-2.56,11.821,11.821,0,0,1-3.26,0,1.657,1.657,0,0,1-1.5-1.33l6.23-1.68,6.04,3.81Z"
        fill="#fcd3c4"
      />
      <path
        d="M31.62,28.6a1.7,1.7,0,0,1-.27.39,1.668,1.668,0,0,1-1.61.47,1.532,1.532,0,0,1-.77-.39,2.828,2.828,0,0,1,.68.99,1.433,1.433,0,0,1-.37,1.66,1.557,1.557,0,0,1-1.84.37h-.01a2.909,2.909,0,0,1-.82-.56l-.51-.45a1.636,1.636,0,0,1,.46.83,1.681,1.681,0,0,1-.63,1.75,1.63,1.63,0,0,1-1.52.24,1.826,1.826,0,0,1-.74-.42l-.35-.34a1.965,1.965,0,0,1,.28.63,1.635,1.635,0,0,1-.37,1.52.276.276,0,0,1-.09.07c-4.7-5.81-8.27-6.45-8.98-9.69-.34-1.55-2.35-.52-2.35-1.92V16.44H12.9l3.18-1.37A2.621,2.621,0,0,1,17.91,15a7.843,7.843,0,0,1,2.57.44c.1.03.21.07.31.11.04.06.08.13.12.2a4.219,4.219,0,0,0,.89,1.13l.91,1.07,1.46,1.71c0,.01.01.02.01.03.05.1.08.15.08.15l.3.29.04.04,6.69,6.4A1.709,1.709,0,0,1,31.62,28.6Z"
        fill="#fde2d8"
      />
      <path
        d="M28.97,29.07a2.828,2.828,0,0,1,.68.99l-4.39-4.2a.242.242,0,0,1,0-.35.251.251,0,0,1,.35-.01l4.13,3.96A1.532,1.532,0,0,1,28.97,29.07Z"
        fill="#fcd3c4"
      />
      <path
        d="M26.61,31.53l-.51-.45a1.636,1.636,0,0,1,.46.83l-3.67-3.77a.242.242,0,0,1,0-.35.25.25,0,0,1,.35,0l4.19,4.3A2.909,2.909,0,0,1,26.61,31.53Z"
        fill="#fcd3c4"
      />
      <path
        d="M23.67,33.48l-.35-.34a1.965,1.965,0,0,1,.28.63l-3.24-3.36a.248.248,0,1,1,.36-.34l3.69,3.83A1.826,1.826,0,0,1,23.67,33.48Z"
        fill="#fcd3c4"
      />
      <path
        d="M32.09,20.07a4.059,4.059,0,0,1-2.6-.17,7.5,7.5,0,0,1-2.79-2.17,5.988,5.988,0,0,1-3.99.22,3.533,3.533,0,0,1-2.23-2.51c.1.03.21.07.31.11.04.06.08.13.12.2a3.83,3.83,0,0,0,1.81,1.63c1.59.61,4.22-.48,4.22-.48a5.658,5.658,0,0,0,2.9,2.35,6.345,6.345,0,0,0,2.87.15C32.79,19.54,32.63,19.86,32.09,20.07Z"
        fill="#fab79e"
      />
    </svg>
  );
};

IconFriendship.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

IconFriendship.propTypes = { rootClassName: string, className: string };

export default IconFriendship;
