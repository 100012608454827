import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconTrailRun.module.css';

const IconTrailRun = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg
      className={classes}
      width="35"
      height="35"
      viewBox="0 0 512 512"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g transform="matrix(1.0448856,0,0,1.0448856,-4534.0953,-3700.825)">
        <g>
          <rect height="92.699997" fill="#484a50" width="10" x="4506.5" y="3628.5"/>
        </g>
        <path d="m 4565.7,3631.3 12.9,-12.9 c 3.1,-3.1 0.9,-8.5 -3.5,-8.5 h -63.6 c -2.8,0 -5,2.2 -5,5 v 40 c 0,2.8 2.2,5 5,5 h 63.6 c 4.5,0 6.7,-5.4 3.5,-8.5 l -12.9,-12.9 c -2,-2.1 -2,-5.3 0,-7.2 z" fill="#c03a32"/>
        <path d="m 4507.2,3694.7 -151.9,261.6 c -1.9,3.3 0.5,7.5 4.3,7.5 H 4672 l -156.2,-269.1 c -1.9,-3.3 -6.7,-3.3 -8.6,0 z" fill="#221b57"/>
        <path d="m 4633,3782.8 -172.5,172.5 c -3.1,3.1 -0.9,8.5 3.5,8.5 h 345 c 4.5,0 6.7,-5.4 3.5,-8.5 L 4640,3782.8 c -1.9,-2 -5.1,-2 -7,0 z" fill="#0099e2"/>
        <path d="m 4559.7,3770.3 -43.9,-75.6 c -1.9,-3.3 -6.7,-3.3 -8.6,0 l -44.3,76.3 16.8,11.5 26.7,-24 33.3,20.8 20,-9 z" fill="#f5f5f8"/>
        <path d="m 4686.3,3829.1 -46.3,-46.3 c -2,-2 -5.1,-2 -7.1,0 l -42.7,42.7 20.2,11.9 26.1,-20 35.5,23.7 14.3,-12 z" fill="#f5f5f8"/>
      </g>
    </svg>
  );
};

IconTrailRun.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

IconTrailRun.propTypes = { rootClassName: string, className: string };

export default IconTrailRun;
