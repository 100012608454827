import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconRunwithaChampion.module.css';

const IconRunwithaChampion = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg
      className={classes}
      width="35"
      height="35"
      viewBox="0 0 128 128"
      xmlns="http://www.w3.org/2000/svg"
    >
<path d="M27.42857,0h73.14286a2.50838,2.50838,0,0,1,2.56072,2.45252V5.80671a2.50838,2.50838,0,0,1-2.56072,2.45252H27.42857a2.50837,2.50837,0,0,1-2.56072-2.45252V2.45252A2.50837,2.50837,0,0,1,27.42857,0Z" fill="#fdb924"/>
<path d="M30.13356,8.25923H97.86644v43.0634c0,1.65906-1.44266,2.27219-2.81319,3.06565L63.92787,72.06086,32.94675,54.38828c-1.37053-.79346-2.81319-1.40659-2.81319-3.06565Z" fill="#0099e2"/>
<path d="M30.13356,8.25923H48.34714V63.1885L32.94675,54.38828c-1.37053-.79346-2.81319-1.40659-2.81319-3.06565Zm49.5193,0H97.86644v43.0634c0,1.65906-1.44266,2.27219-2.81319,3.06565L79.65286,63.15244Z" fill="#221b57"/>
<circle cx="64" cy="86.55959" r="41.44041" fill="#ffd36c"/>
<circle cx="64" cy="86.55959" r="35.7419" fill="#fdb10d"/>
<circle cx="64" cy="86.55959" r="34.58777" fill="#fdb924"/>
<g opacity="0.5">
<path d="M57.90476,80.03156c-1.37053,0-2.48859.1082-3.89518.07213a51.71125,51.71125,0,0,0-7.39363.57706,3.973,3.973,0,0,0-1.37053.61313,1.75524,1.75524,0,0,0-.79346,1.22626v.36066a4.06256,4.06256,0,0,0,1.19019,2.164L54.15385,91.645l-1.58693,5.33784c-.25247.90166-.541,1.76726-.79346,2.66892-1.082,3.787.18033,6.92477,4.328,4.54438.90166-.61313,1.73119-1.19019,2.63285-1.80332l3.246-2.23612c.25247-.18033.4328-.28853.68526-.46886,1.69513-1.19019.90166-1.19019,3.28205.39673,1.51479,1.00986,6.41984,4.29191,7.069,4.58045,1.91152.8656,3.67878.50493,3.67878-1.58693a13.2978,13.2978,0,0,0-.82953-4.65258L73.84615,91.645a12.88055,12.88055,0,0,1,1.26233-1.00986l6.528-5.01324A3.83474,3.83474,0,0,0,83.548,82.48408a1.86445,1.86445,0,0,0-.79346-1.19019c-1.37053-1.00986-3.49845-.7574-4.94111-1.00986-.50493-.1082-7.28543-.25247-7.79036-.25247-.03607-.36066-.90166-2.705-1.11806-3.31812l-2.45252-6.528c-1.94759-3.89518-4.364-1.94759-5.55424,1.55086Z" fill="#fdb10d"/>
</g>
<path d="M57.58016,79.67089c-1.33446,0-2.45252.1082-3.787.07213a48.62828,48.62828,0,0,0-7.17723.57706,4.049,4.049,0,0,0-1.33446.57706,1.59487,1.59487,0,0,0-.7574,1.19019V82.448a4.082,4.082,0,0,0,1.11806,2.12792l8.29529,6.38377-1.55086,5.19358c-.25247.8656-.50493,1.69513-.7574,2.59679a9.68283,9.68283,0,0,0-.57706,3.71485,1.85028,1.85028,0,0,0,1.76726,1.91152,5.11961,5.11961,0,0,0,2.99352-1.22626c.90166-.57706,1.69513-1.15413,2.56072-1.76726l3.13779-2.164a7.18413,7.18413,0,0,1,.68526-.4328c1.65906-1.15413.8656-1.15413,3.17385.36066,1.47873.9738,6.38377,4.50831,7.033,4.79684,1.83939.79346,3.57058.46886,3.57058-1.55086a13.781,13.781,0,0,0-.9738-4.869l-1.94759-6.5641a7.26992,7.26992,0,0,1,1.26233-.9738l6.31164-4.869A3.6439,3.6439,0,0,0,82.466,82.05128a1.832,1.832,0,0,0-.7574-1.15413c-1.33446-.9738-3.39025-.72133-4.79684-.9738-.50493-.1082-7.069-.25247-7.5379-.25247-.03607-.36066-.90166-2.59679-1.11806-3.20992l-2.38039-6.3477c-1.87546-3.787-4.21978-1.87546-5.37391,1.51479Z" fill="#ffd36c"/>
<g opacity="0.2">
<path d="M73.738,57.30966a30.03464,30.03464,0,0,1,1.40659,58.06706c8.04283-6.1313,13.3446-16.62666,13.3446-28.5286C88.48915,74.297,82.57425,63.2967,73.738,57.30966Z" fill="#fff"/>
</g>
<g opacity="0.15">
<polygon points="30.134 8.259 97.866 8.259 97.866 10.928 30.134 10.928 30.134 8.259" fill="#1a1a1a"/>
</g>
<g opacity="0.1">
<path d="M64,45.11921A41.28583,41.28583,0,0,1,92.06856,56.08l1.57808-.89819a41.4169,41.4169,0,0,0-59.40148-.07222l1.69317.96373A41.286,41.286,0,0,1,64,45.11921Z" fill="#1a1a1a"/>
</g>
    </svg>
  );
};

IconRunwithaChampion.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

IconRunwithaChampion.propTypes = { rootClassName: string, className: string };

export default IconRunwithaChampion;
