import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconEmailSuccess.module.css';

const IconEmailSuccess = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg
      className={classes}
      width="64"
      height="64"
      viewBox="0 0 64 64"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect height="44" fill="#007db8" width="62" x="1" y="16" />
      <polygon points="32 36 1 60 63 60 32 36" fill="#0099e2" />
      <path d="M1,16,30.648,38.953a2.206,2.206,0,0,0,2.7,0L63,16Z" fill="#33beff" />
      <circle cx="32" cy="16" r="12" fill="#ffffff" />
      <path
        d="M30,21a1,1,0,0,1-.707-.293l-4-4a1,1,0,0,1,1.414-1.414L30,18.586l7.293-7.293a1,1,0,0,1,1.414,1.414l-8,8A1,1,0,0,1,30,21Z"
        fill="#2ecc71"
      />
    </svg>
  );
};

IconEmailSuccess.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

IconEmailSuccess.propTypes = { rootClassName: string, className: string };

export default IconEmailSuccess;
