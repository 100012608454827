import React from 'react';
import { string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { NamedLink } from '../../components';
import { Controller, Scene } from 'react-scrollmagic';

import css from './SectionShareYourRun.module.css';

const SectionShareYourRun = props => {
  const { rootClassName, className } = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div className={css.shareYourRunContent}>
        <Controller>
          <Scene duration={0} classToggle={css.shareYourRunMainTitle} offset={-800}>
            <h1 className={css.shareYourRunMainTitleInvisible}>
              <FormattedMessage id="SectionShareYourRun.title" />
            </h1>
          </Scene>
        </Controller>
        <Controller>
          <Scene duration={0} classToggle={css.shareYourRunSubTitle} offset={-800}>
            <h2 className={css.shareYourRunSubTitleInvisible}>
              <FormattedMessage id="SectionShareYourRun.subTitle" />
            </h2>
          </Scene>
        </Controller>
        <Controller>
          <Scene duration={0} classToggle={css.buttons} offset={-800}>
            <div className={css.buttonsInvisible}>
              <div className={css.offerButtonSection}>
                <NamedLink name="NewListingPage" className={css.shareYourRunButton}>
                  <FormattedMessage id="SectionShareYourRun.offerButton" />
                </NamedLink>
              </div>
              <div className={css.browseButtonSection}>
                <NamedLink
                  name="SearchPage"
                  to={{
                    search:
                      '',
                  }}
                  className={css.shareYourRunButton}
                >
                  <FormattedMessage id="SectionShareYourRun.browseButton" />
                </NamedLink>
              </div>
            </div>
          </Scene>
        </Controller>
      </div>
    </div>
  );
};

SectionShareYourRun.defaultProps = { rootClassName: null, className: null };

SectionShareYourRun.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionShareYourRun;
