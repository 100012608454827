import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { TopbarContainer } from '../../containers';
import {
  Page,
  LayoutSideNavigation,
  LayoutWrapperMain,
  LayoutWrapperSideNav,
  LayoutWrapperTopbar,
  LayoutWrapperFooter,
  Footer,
  ExternalLink,
  NamedLink,
} from '../../components';
import config from '../../config';

import css from './FAQPage.module.css';
import image from './faq.jpg';

const FAQPageComponent = props => {
  const { scrollingDisabled, intl } = props;

  const tabs = [
    {
      text: intl.formatMessage({ id: 'AboutPage.aboutTabTitle' }),
      selected: false,
      linkProps: {
        name: 'AboutPage',
      },
    },
    {
      text: intl.formatMessage({ id: 'ContactPage.contactTabTitle' }),
      selected: false,
      linkProps: {
        name: 'ContactPage',
      },
    },
    {
      text: intl.formatMessage({ id: 'FAQPage.faqTabTitle' }),
      selected: true,
      linkProps: {
        name: 'FAQPage',
      },
    },
  ];
  const siteTitle = config.siteTitle;
  const schemaTitle = intl.formatMessage({ id: 'FAQPage.schemaTitle' }, { siteTitle });
  const schema = {
    '@context': 'http://schema.org',
    '@type': 'WebPage',
    name: schemaTitle,
  };
  return (
    <Page title={schemaTitle} scrollingDisabled={scrollingDisabled} schema={schema}>
      <LayoutSideNavigation>
        <LayoutWrapperTopbar>
          <TopbarContainer currentPage="FAQPage" />
        </LayoutWrapperTopbar>
        <LayoutWrapperSideNav tabs={tabs} />
        <LayoutWrapperMain>
          <div className={css.headingContainer}>
            <div className={css.heading}>
              <FormattedMessage id="FAQPage.heading" />
            </div>
            <div className={css.subHeading}>
              <FormattedMessage id="FAQPage.subHeading" />
            </div>
          </div>
          <div className={css.content}>
            <p>
              The below is to provide guidance and is kept up to date to the best of our knowledge but is of purely informational character. SHARE YOUR RUN cannot be hold accountable for the below statements. For legally binding information, please refer to our <a href="/privacy-policy">Privacy policy</a> and our <a href="/terms-of-service">Terms of service</a>.<br />
              <br />
            </p>
            <h2 className={css.subtitle}>Basics</h2>
            <div className={css.tabs}>
              <div className={css.tab}>
                <input className={css.faqinput} type="checkbox" id="chck101" />
                <label className={css.tablabel} htmlFor="chck101">
                  How does SHARE YOUR RUN work?
                </label>
                <div className={css.tabcontent}>
                  SHARE YOUR RUN is a global marketplace, where anyone can book and offer running experiences. Have you ever had the feeling that it just "clicked" when you were talking to another runner? Yes, we know that feeling too.<br />
                  <br />
                  We believe that runners are like-minded people and share a common understanding of themselves and the world. We would like to facilitate meeting fellow runners - be it in your home town, or all over the world.<br />
                  <br />
                  We recommend to just browse through the <NamedLink name="SearchPage" to={{search:'',}}>offered runs</NamedLink> and you will immediately understand what we mean!<br />
                  <br />
                  And hopefully soon afterwards, you will be <NamedLink name="NewListingPage">offering</NamedLink> your own favourite runs on SHARE YOUR RUN. You don't need to be a certified running coach or a pro runner. <strong>We are convinced that any runner is able to provide a great running experience to fellow runners!</strong>
                </div>
              </div>
              <div className={css.tab}>
                <input  className={css.faqinput} type="checkbox" id="chck102" />
                <label className={css.tablabel} htmlFor="chck102">Why should I spend money to run with other people?</label>
                <div className={css.tabcontent}>
                  Probably, you have been running with your friends. Hopefully, you don't have to pay friends in order to run with you. &#128540; However, imagine the following situations:<br />
                    <br />
                    <div className={css.list}>You are on a business trip and would like to get an efficient training in, without studying the map beforehand.</div>
                    <div className={css.list}>You are on holidays and would like to explore your destination together with a local.</div>
                    <div className={css.list}>You are always running the same standard routes and would be interested to get to know other paths or any secret highlights around you.</div>
                    <div className={css.list}>You are not good with maps, get lost or run into dead ends.</div>
                    <div className={css.list}>You are bored during your runs and want to run with a real person - instead of listening to the same playlist over and over again.</div>
                    <div className={css.list}>Or simply browse through the <NamedLink name="SearchPage" to={{search:'',}}>offered runs</NamedLink> - you will be surprised!</div>
                  <br />
                  We strongly believe that you have never paid for a run, because there simply was no worthwhile offer so far. This shall change.<br />
                </div>
              </div>
              <div className={css.tab}>
                <input  className={css.faqinput} type="checkbox" id="chck103" />
                <label className={css.tablabel} htmlFor="chck103">Why is everything in English and in EUR?</label>
                <div className={css.tabcontent}>
                  To keep things simple, we focus on one language and one currency. However, your running guide may speaks additional (your!) languages. You can see these in the details of the runs.
                </div>
              </div>
              <div className={css.tab}>
                <input  className={css.faqinput} type="checkbox" id="chck104" />
                <label className={css.tablabel} htmlFor="chck104">Where can I send feedback?</label>
                <div className={css.tabcontent}>
                  We are very interested to hear what you think about our baby! Whether you like it - or hate it - or have suggestions how to make it even better: please <a href="/contact">contact us</a>!
                </div>
              </div>
              <div className={css.tab}>
                <input  className={css.faqinput} type="checkbox" id="chck105" />
                <label className={css.tablabel} htmlFor="chck105">What about insurance?</label>
                <div className={css.tabcontent}>
                  The runners and the guides are responsible for their own insurances. SHARE YOUR RUN accepts no liability. Thank you for your understanding! &#128536;
                </div>
              </div>
              <div className={css.tab}>
                <input className={css.faqinput} type="checkbox" id="chck106" />
                <label className={css.tablabel} htmlFor="chck106">What keeps me from booking the run outside the SHARE YOUR RUN marketplace via direct messages to the guide?</label>
                <div className={css.tabcontent}>
                  Nothing.<br />
                  <br />
                  However, we naively believe that runners are honest and smart people. If you transact outside SHARE YOUR RUN, we obviously don't make any money - and it will just be a matter of time, before we need to close the marketplace due to the running costs. In such cases, we reserve the right to block your account.
                </div>
              </div>
              <div className={css.tab}>
                <input className={css.faqinput} type="checkbox" id="chck107" />
                <label className={css.tablabel} htmlFor="chck107">Is my data secure with you?</label>
                <div className={css.tabcontent}>
                  We do not sell your data or do funny things with it.<br />
                  <br />
                  Under the hood, we are using <ExternalLink href="https://www.sharetribe.com">Sharetribe</ExternalLink> (hosted on Amazon Web Services in the EU and the US), <ExternalLink href="https://www.heroku.com">Heroku</ExternalLink> (by Salesforce - also hosted on Amazon Web Services) and <ExternalLink href="https://www.stripe.com">Stripe</ExternalLink> (for all payment services).<br />
                  <br />
                  Wherever possible, we choose privacy friendly alternatives, such as <ExternalLink href="https://www.plausible.io">plausible.io</ExternalLink> instead of G*** Analytics or <ExternalLink href="http://vimeo.com/shareyourrun">Vimeo</ExternalLink> instead of Y**T***. This will cost us a bit more but reflects <NamedLink name="AboutPage">our values</NamedLink> - to build a high-quality marketplace for you.<br />
                  <br />
                  We are aware that your data is of value to you, and we will treat it respectively. Please also refer to our <NamedLink name="PrivacyPolicyPage">Privacy policy</NamedLink>.
                </div>
              </div>
              <div className={css.tab}>
                <input className={css.faqinput} type="checkbox" id="chck108" />
                <label className={css.tablabel} htmlFor="chck108">Is there a mobile app?</label>
                <div className={css.tabcontent}>
                  No.<br />
                  <br />
                  However, SHARE YOUR RUN has been developed with responsive web design in mind and will look great on all possible screen sizes (including your mobile phone). Try it out!
                </div>
              </div>
              <div className={css.tab}>
                <input  className={css.faqinput} type="checkbox" id="chck109" />
                <label className={css.tablabel} htmlFor="chck109">I love SHARE YOUR RUN! How can I support you?</label>
                <div className={css.tabcontent}>
                  Many thanks that you actually reading this question. We can feel the &#10084;&#65039;!<br />
                  If you want to support us, please:<br />
                  <br />
                  <div className={css.list}><strong>Offer a run yourself!</strong><br />
                  This will help us to grow the community! (And help us cover the costs for running ("running" &#129313;) this marketplace.)</div>
                  <div className={css.list}><strong>Share the word!</strong><br />
                  Recommend SHARE YOUR RUN to your own local (and global) running community.</div>
                  <div className={css.list}><strong>Tag us!</strong><br />
                  Post SHARE YOUR RUN runs and/or pictures of them on your social media channels - and please don't forget to tag us, so we can re-share it. (<ExternalLink href="https://www.facebook.com/shareyourruncom">Facebook</ExternalLink>, <ExternalLink href="https://twitter.com/share_your_run">Twitter</ExternalLink>, <ExternalLink href="https://www.instagram.com/share_your_run">Instagram</ExternalLink>, <ExternalLink href="https://www.linkedin.com/company/share-your-run">LinkedIn</ExternalLink>, <ExternalLink href="https://www.strava.com/clubs/share-your-run">Strava</ExternalLink>.)</div>
                </div>
              </div>
            </div>
            <h2 className={css.subtitle}>Booking a run</h2>
            <div className={css.tabs}>
              <div className={css.tab}>
                <input className={css.faqinput} type="checkbox" id="chck201" />
                <label className={css.tablabel} htmlFor="chck201">How does the booking process work exactly?</label>
                <div className={css.tabcontent}>
                  <div className={css.list}>Browse through the <NamedLink name="SearchPage" to={{search:'',}}>offered runs</NamedLink>.</div>
                  <div className={css.list}>On the run you like, enter an available date/time and the number of runners. Hit "Request to book".</div>
                  <div className={css.list}>Enter your credit card details and hit "Send booking request". The total price will be reserved on your credit card, but not yet charged.</div>
                  <div className={css.list}><strong>The guide will receive an email and has 3 days (i.e. 72 hours) to accept or decline your booking request.</strong></div>
                  <br />
                  &#9989; Should the guide <strong>accept</strong> your booking request &#129310;, you will get a confirmation email. The amount will stay reserved on your credit card, but finally charged only when the run starts.<br />
                  <br />
                  &#10060; Should the guide <strong>decline</strong> your booking request, you will get a respective email and the reserved amount on your credit card will be cancelled. Your credit card will of course NOT be charged.<br />
                  <br />
                  &#129335; Should the guide <strong>not react</strong>, your booking request will automatically be declined after 3 days. If you booked less than 3 days before the run and the guide doesn't react, your booking request will be automatically declined 12 hours before the start of the run. The reserved amount on your credit card will be cancelled and your credit card will of course NOT be charged.<br />
                </div>
              </div>
              <div className={css.tab}>
                <input className={css.faqinput} type="checkbox" id="chck202" />
                <label className={css.tablabel} htmlFor="chck202">What is the cancellation policy?</label>
                <div className={css.tabcontent}>
                  <strong>As a guest</strong><br />
                  <div className={css.list}>If you cancel <strong>72 hours or earlier</strong> before the start of the run, you will not be charged. The reservation on your credit card is cancelled for free.</div>
                  <div className={css.list}>If you cancel <strong>later than 72 hours</strong> before the start of a run, the guide will be informed, but <span className={css.fail}><strong>you will still be charged the full amount!</strong></span> If you need to cancel on such short notice, we strongly advise you to contact your guide and find a solution that works for both of you.</div>
                  <br />
                  <strong>As a guide</strong><br />
                  <div className={css.list}>You can cancel for free any time before the start of the run. However, if you do so, you will probably receive negative reviews and as a consequence less bookings. In case you need to cancel, we strongly advise to contact your guest via direct message and find a solution that works for both of you.</div>
                </div>
              </div>
              <div className={css.tab}>
                <input className={css.faqinput} type="checkbox" id="chck203" />
                <label className={css.tablabel} htmlFor="chck203">What does a run cost?</label>
                <div className={css.tabcontent}>
                  <div className={css.list}>When you browse for a run, you will be able to see its price.</div>
                  <div className={css.list}>In case you would like to book for more than one person: Any additional runner will cost an additional 33% of the initial price.</div>
                  <div className={css.list}>Depending on the currency of your credit card, you might be charged with an additional fee by your credit card provider.</div>
                  <div className={css.list}>Setting the price for the run, is completely up to the guide - between a minimum of EUR 10.- and a maximum of EUR 500.-.</div>
                </div>
              </div>
              <div className={css.tab}>
                <input className={css.faqinput} type="checkbox" id="chck204" />
                <label className={css.tablabel} htmlFor="chck204">Why can I see the run, but cannot book it?</label>
                <div className={css.tabcontent}>
                  Most probably, the guide has published the run, but has not provided availability (dates/times) for it. We strongly suggest contacting the guide via direct message. Use the "Contact" link on the run details page!
                </div>
              </div>
              <div className={css.tab}>
                <input className={css.faqinput} type="checkbox" id="chck205" />
                <label className={css.tablabel} htmlFor="chck205">Why can I not book a run in 4 months from now?</label>
                <div className={css.tabcontent}>
                  Due to limitations in making reservations on credit cards, you can book a run earliest 90 days (and latest 24 hours) in advance of the start of the run. Thank you for your understanding! &#128536;
                </div>
              </div>
              <div className={css.tab}>
                <input className={css.faqinput} type="checkbox" id="chck206" />
                <label className={css.tablabel} htmlFor="chck206">Will there be other guest runners on the run?</label>
                <div className={css.tabcontent}>
                  No.<br />
                  <br />
                  Even if you don't book for the maximum number of guest runners, your booking is exclusive. It will automatically remove the availability of the guide for the respective run, so that no other runner can book the same run for the same date/time.
                </div>
              </div>
            </div>
            <h2 className={css.subtitle}>Offering a run</h2>
            <div className={css.tabs}>
              <div className={css.tab}>
                <input className={css.faqinput} type="checkbox" id="chck301" />
                <label className={css.tablabel} htmlFor="chck301">How much does the running guide get?</label>
                <div className={css.tabcontent}>
                  <div className={css.list}><strong>85% of the full amount is paid to the running guide. &#129297;</strong></div>
                  <div className={css.list}><strong>2-5%</strong> is kept by <ExternalLink href="https://www.stripe.com">Stripe</ExternalLink> for providing the payment service (including charges for credit cards).</div>
                  <div className={css.list}><strong>ca. 10%</strong> will actually be paid out to SHARE YOUR RUN. We use this money to run, improve and promote this marketplace.</div>
                </div>
              </div>
              <div className={css.tab}>
                <input className={css.faqinput} type="checkbox" id="chck302" />
                <label className={css.tablabel} htmlFor="chck302">Where is my money?</label>
                <div className={css.tabcontent}>
                  It can take 7-14 days for the funds to be paid to your account.<br />
                  Thank you for your understanding.<br />
                </div>
              </div>
              <div className={css.tab}>
                <input className={css.faqinput} type="checkbox" id="chck303" />
                <label className={css.tablabel} htmlFor="chck303">How can I offer a run?</label>
                <div className={css.tabcontent}>
                  <div className={css.list}>We would suggest to first <NamedLink name="SearchPage" to={{search:'',}}>browse through some existing runs</NamedLink> to get an idea of how the runs are presented.</div>
                  <div className={css.list}>Hit "<NamedLink name="NewListingPage">+ Offer a run</NamedLink>" on the top right.</div>
                  <div className={css.list}>If you are not logged in, you are asked to log in (or sign up if you do not have an account yet).</div>
                  <div className={css.list}>Complete the 7 steps of the listing wizard. &#129497;</div>
                  <div className={css.list}>If you have not entered your pay-out details yet, you will be asked to enter your bank account, where you want your portion of the payments to be paid out. This is being managed by <ExternalLink href="https://www.stripe.com">Stripe</ExternalLink>. To prevent money laundering and fraud, depending on your location, Stripe will ask you for further details in order to identify you properly. Checking your submissions can take a few days! During that time, you will be able to register runs on SHARE YOUR RUN, but you cannot yet publish them.</div>
                  <div className={css.list}>Once your identity has been verified by Stripe, your runs will be published automatically and available to be booked for runners all over the world!</div>
                </div>
              </div>
              <div className={css.tab}>
                <input className={css.faqinput} type="checkbox" id="chck304" />
                <label className={css.tablabel} htmlFor="chck304">Who can offer a run?</label>
                <div className={css.tabcontent}>
                  A.N.Y.O.N.E.<br />
                  <br />
                  We are convinced anyone can offer a run and is able to provide a running experience to another runner. Have you ever thought about sharing your most favourite running routes with fellow runners? You can do so now - and even earn some money with it! Ok, we admit: There is one caveat: You need to be located in one of <ExternalLink href="https://stripe.com/global">these 40+ countries</ExternalLink>.<br />
                  <br />
                  SHARE YOUR RUN is designed to be a "<ExternalLink href="https://en.wikipedia.org/wiki/Online_marketplace#The_sharing_economy">peer-2-peer</ExternalLink>" marketplace. We facilitate sellers to find buyers (and vice versa).
                </div>
              </div>
              <div className={css.tab}>
                <input className={css.faqinput} type="checkbox" id="chck305" />
                <label className={css.tablabel} htmlFor="chck305">How does the booking process work exactly?</label>
                <div className={css.tabcontent}>
                  <div className={css.list}>Once a guest runner books your run, you will receive a booking request that you can process via your "<a href="/inbox/sales">Bookings</a>" inbox. You will also be notified via email. Guest runners are able to book runs until 24 hours until the start of the run latest.</div>
                  <div className={css.list}>You have 3 days (i.e. 72 hours) to accept or decline the booking request before it will expire (i.e. be auto declined). In case the booking request was sent less than 72 hours before, the booking request will expire (i.e. be auto declined) 12 hours before the start of the run.</div>
                  <div className={css.list}>Should you decline (or the system auto decline) a run, the reserved amount on the credit card of the customer will be released and the customer will not be charged - i.e. no costs for guest runners or guides.</div>
                  <div className={css.list}>Should you accept a booking request, SHARE YOUR RUN will confirm the booking to the guest runner via email (including your email address and your phone number - if you have provided it <a href="/account/contact-details">in your account settings</a>). The amount will be reserved on the credit card of the guest runner, but finally charged only when the run starts.</div>
                  <br />
                  At all times, the guest runner is able to contact you directly via direct message on the SHARE YOUR RUN marketplace. All direct messages will trigger an email notification to the receiver.
                </div>
              </div>
              <div className={css.tab}>
                <input className={css.faqinput} type="checkbox" id="chck306" />
                <label className={css.tablabel} htmlFor="chck306">What is the cancellation policy?</label>
                <div className={css.tabcontent}>
                  <strong>As a guide</strong><br />
                  <div className={css.list}>You can cancel for free any time before the start of the run. However, if you do so, you will probably receive negative reviews and as a consequence less bookings. In case you need to cancel, we strongly advise to contact your guest via direct message and find a solution that works for both of you.</div>
                  <br />
                  <strong>As a guest</strong><br />
                  <div className={css.list}>If you cancel <strong>72 hours or earlier</strong> before the start of the run, you will not be charged. The reservation on your credit card is cancelled for free.</div>
                  <div className={css.list}>If you cancel <strong>later than 72 hours</strong> before the start of a run, the guide will be informed, but <span className={css.fail}><strong>you will still be charged the full amount!</strong></span> If you need to cancel on such short notice, we strongly advise you to contact your guide and find a solution that works for both of you.</div>
                </div>
              </div>
              <div className={css.tab}>
                <input className={css.faqinput} type="checkbox" id="chck307" />
                <label className={css.tablabel} htmlFor="chck307">Can I decide when I can be booked?</label>
                <div className={css.tabcontent}>
                  Of course!<br />
                  <br />
                  <div className={css.list}>You can define your availability for every run you offer separately when you initially publish your run.</div>
                  <div className={css.list}>Make sure you enter an availability window that is at least as long as the duration of your run. For example: If you enter an availability window of only 1 hour, a 1.5 hour run cannot be booked on the respective day.</div>
                  <div className={css.list}>To update your availability for an existing run, click on your avatar on the top right and go to "<a href="/listings">Your runs</a>". Below each run, you find a link to "Manage availability".</div>
                  <div className={css.list}>You can use the "Default schedule" to define your general availability. Use the "Exceptions" to define additional (un-) availabilities for specific dates/times.</div>
                  <br />
                  Please note that you need to specify your availability for every run you offer separately. If you offer many runs &#129310;, this is a bit more work for you, but it also gives you the possibility to offer your city run on Wednesdays only and your trail run on Saturdays/Sundays only.
                </div>
              </div>
              <div className={css.tab}>
                <input className={css.faqinput} type="checkbox" id="chck308" />
                <label className={css.tablabel} htmlFor="chck308">Why did my availability disappear?</label>
                <div className={css.tabcontent}>
                  Once you accept a booking request, we will remove all availability for that specific run on the respective calendar day, so you don't need to do it manually.<br />
                  <br />
                  In case you would like to guide more than one run per day &#128170;, you can simply publish the same (or better yet: similar!) run twice.
                </div>
              </div>
              <div className={css.tab}>
                <input className={css.faqinput} type="checkbox" id="chck309" />
                <label className={css.tablabel} htmlFor="chck309">How can I delete a run?</label>
                <div className={css.tabcontent}>
                  Click on your avatar on the top right and go to "<a href="/listings">Your runs</a>". On the top right of the run pictures, click on the "..." and then choose "Close this run". The run is then in a "closed" state and therefore no longer visible on the marketplace. However, you can always re-open the any closed runs be using the "Open run" button on the same page.
                </div>
              </div>
              <div className={css.tab}>
                <input className={css.faqinput} type="checkbox" id="chck310" />
                <label className={css.tablabel} htmlFor="chck310">Why do I not get any booking requests?</label>
                <div className={css.tabcontent}>
                  Try to book your own run yourself. If you are able to select a date and a time, you are good to go and it's not a technical issue preventing others to book from you.<br />
                  <br />
                  <div className={css.list}>Make sure you have verified your email address. There should be a green text that reads "Your email address is verified." <a href="/account/contact-details">here</a>.</div>
                  <div className={css.list}>Make sure your pay-out details are correct and up to date. There should be a green box that reads "Your Stripe account is up to date!" <a href="/account/payments">here</a>.</div>
                  <div className={css.list}>Make sure your run is not closed. You should be able to see the picture of your run <a href="/listings">here</a>.</div>
                  <div className={css.list}>Make sure you have availabilities (under "Manage availability") for the next 90 days entered for EVERY run you offer <a href="/listings">here</a>.</div>
                  <div className={css.list}>Make sure you have entered an appropriate availability window for the respective run <a href="/listings">here</a> (under "Manage availability"). For example: If you enter an availability window of only 1 hour, a 1.5 hour run cannot be booked on the respective day.</div>
                  <div className={css.list}>Make sure you include some local highlights and fun facts in the description of your run in order to provide a unique running experience to potential customer runners.</div>
                  <div className={css.list}>Make sure you advertise your runs on your own social media channels - tagging us (so we can re-share)!</div>
                  <br />
                  Should you still have problems, please <a href="/contact">contact us</a>.
                </div>
              </div>
              <div className={css.tab}>
                <input className={css.faqinput} type="checkbox" id="chck311" />
                <label className={css.tablabel} htmlFor="chck311">How is the price for additional guest runners calculated?</label>
                <div className={css.tabcontent}>
                  The first runner is charged the price you defined, when you initially offer the run. Every additional runner is charged an additional 33% of the original price for the 1st runner.<br />
                  <br />
                  For example:<br />
                  1 runner = <strong>EUR 100</strong><br />
                  2 runners = EUR 100 + EUR 33 = <strong>EUR 133</strong><br />
                  3 runners = EUR 100 + EUR 33 + EUR 33 = <strong>EUR 166</strong><br />
                  4 runners = EUR 100 + EUR 33 + EUR 33 + EUR 33 = <strong>EUR 199</strong><br />
                  <br />
                  For simplicity and transparency reasons towards the runners, we do not allow any other formulas. Thank you for your understanding! &#128536;
                </div>
              </div>
              <div className={css.tab}>
                <input className={css.faqinput} type="checkbox" id="chck312" />
                <label className={css.tablabel} htmlFor="chck312">Can I be paid in USD (or any other currency)?</label>
                <div className={css.tabcontent}>
                  Sure!<br />
                  <br />
                  Just enter your bank respective bank account details <a href="/account/payments">here</a>.
                </div>
              </div>
              <div className={css.tab}>
                <input className={css.faqinput} type="checkbox" id="chck313" />
                <label className={css.tablabel} htmlFor="chck313">Do I need to pay taxes?</label>
                <div className={css.tabcontent}>
                  We have no clue - honestly. We are runners, not tax lawyers (phew!).<br />
                  <br />
                  SHARE YOUR RUN GmbH is domiciled in Zürich (Switzerland), where it is subject to taxes. Our guides are responsible to make sure their income is taxed correctly. Please check with your local authorities.
                </div>
              </div>
              <div className={css.tab}>
                <input className={css.faqinput} type="checkbox" id="chck314" />
                <label className={css.tablabel} htmlFor="chck314">Ich möchte zwar einen Lauf anbieten, spreche aber kein Englisch?</label>
                <div className={css.tabcontent}>
                  Das ist überhaupt kein Problem. Veröffentliche Deinen Lauf einfach in Deutsch. Beim Erfassen kannst Du ausserdem bei Punkt "3. Languages" angeben, welche Sprachen Du sprichst.
                </div>
              </div>
            </div>
          </div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSideNavigation>
    </Page>
  );
};

const { bool } = PropTypes;

FAQPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  return {
    scrollingDisabled: isScrollingDisabled(state),
  };
};

const FAQPage = compose(
  connect(mapStateToProps),
  injectIntl
)(FAQPageComponent);

export default FAQPage;
