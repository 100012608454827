import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';
import Vimeo from '@u-wave/react-vimeo';

import css from './SectionVideo.module.css';

const SectionShareYourRun = props => {
  const { rootClassName, className } = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <Vimeo video="https://vimeo.com/544324690/f2c24c84bb" responsive dnt />
    </div>
  );
};

SectionShareYourRun.defaultProps = { rootClassName: null, className: null };

SectionShareYourRun.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionShareYourRun;
