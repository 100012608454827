import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { TopbarContainer } from '../../containers';
import {
  Page,
  LayoutSideNavigation,
  LayoutWrapperMain,
  LayoutWrapperSideNav,
  LayoutWrapperTopbar,
  LayoutWrapperFooter,
  Footer,
  ExternalLink,
  IconVision,
  IconMission,
  IconValues,
} from '../../components';
import config from '../../config';
import { Controller, Scene } from 'react-scrollmagic';

import css from './AboutPage.module.css';
import image from './about.jpg';

const AboutPageComponent = props => {
  const { scrollingDisabled, intl } = props;

  const tabs = [
    {
      text: intl.formatMessage({ id: 'AboutPage.aboutTabTitle' }),
      selected: true,
      linkProps: {
        name: 'AboutPage',
      },
    },
    {
      text: intl.formatMessage({ id: 'ContactPage.contactTabTitle' }),
      selected: false,
      linkProps: {
        name: 'ContactPage',
      },
    },
    {
      text: intl.formatMessage({ id: 'FAQPage.faqTabTitle' }),
      selected: false,
      linkProps: {
        name: 'FAQPage',
      },
    },
  ];
  const siteTitle = config.siteTitle;
  const schemaTitle = intl.formatMessage({ id: 'AboutPage.schemaTitle' }, { siteTitle });
  const schema = {
    '@context': 'http://schema.org',
    '@type': 'WebPage',
    name: schemaTitle,
  };
  return (
    <Page title={schemaTitle} scrollingDisabled={scrollingDisabled} schema={schema}>
      <LayoutSideNavigation>
        <LayoutWrapperTopbar>
          <TopbarContainer currentPage="AboutPage" />
        </LayoutWrapperTopbar>
        <LayoutWrapperSideNav tabs={tabs} />
        <LayoutWrapperMain>
          <div className={css.headingContainer}>
            <div className={css.heading}>
              <FormattedMessage id="AboutPage.heading" />
            </div>
            <div className={css.subHeading}>
              <FormattedMessage id="AboutPage.subHeading" />
            </div>
          </div>
          <div className={css.content}>
            <h2>Hello fellow runners! It's Markus and Christian.</h2>
            <p>
              <ExternalLink href="https://www.ckr.ch">Christian</ExternalLink> was infected with the runners' virus in his childhood. His father was an eager runner himself.
              After a break with "no sports" &#9996;&#65039;,  he turned his passion into his career by becoming a semi-professional marathon runner.
              He participated at the Rio 2016 Summer Olympics, 1x at the World Athletics Championships, 3x at the European Athletics
              Championships, and - last but not least - finished the Dragon's Back Race 2019 successfully (i.e. with no permanent damage).
            </p>
            <p>
              Likewise, Markus was infected with the runners' virus in his childhood, as his father too was a devout runner himself.
              More than 30 years ago, Markus turned his passion into his career and founded a <ExternalLink href="https://www.albisreisen.ch">travel agency</ExternalLink> in Zürich (Switzerland) -
              specialised in offering trips to road races all over the world (including the wold marathon majors) and organising/guiding
              running camps. He gained more than 30 years of expertise in providing running experiences to other runners -
              and finshed countless marathons around the world (including 3x the Comrades Marathon - 89 km).
            </p>
          </div>
          <div className={css.headingContainerTwo}>
            <div className={css.heading}>
              <FormattedMessage id="AboutPage.headingTwo" />
            </div>
            <div className={css.subHeading}>
              <FormattedMessage id="AboutPage.subHeadingTwo" />
            </div>
          </div>
          <div className={css.content}>
            <p>
              In 2016, Markus and Christian met for the first time. Quickly, they joined forces and
              since then, Christian has been a regular guest speaker at Markus's seminars for
              marathon preparation.
            </p>
            <p>
              On a summer hike in 2020, they discussed their ideas of a "marketplace for runs" for
              the first time. Since then, they have been haunted by that idea. A few months and
              countless sleepless nights later, the first prototype went live and their startup was
              formally started up.
            </p>
            <h2>
              From the summer of 2021, SHARE YOUR RUN is up and running - chasing fellow runners.
              <br />
              <br />
            </h2>
            <div className={css.steps}>
              <div className={css.step}>
                <Controller>
                  <Scene duration={0} classToggle={css.iconVisible} offset={-200}>
                    <div className={css.iconInvisible}><IconVision /></div>
                  </Scene>
                </Controller>
                <h2 className={css.stepTitle}>Our vision</h2>
                <p>Run with a local. Anywhere you go.</p>
              </div>
              <div className={css.step}>
                <Controller>
                  <Scene duration={0} classToggle={css.iconVisible} offset={-200}>
                    <div className={css.iconInvisible}><IconMission /></div>
                  </Scene>
                </Controller>
                <h2 className={css.stepTitle}>Our mission</h2>
                <p>We help runners all over the world to turn any run into a lasting experience.</p>
              </div>
              <div className={css.step}>
                <Controller>
                  <Scene duration={0} classToggle={css.iconVisible} offset={-200}>
                    <div className={css.iconInvisible}><IconValues /></div>
                  </Scene>
                </Controller>
                <h2 className={css.stepTitle}>Our values</h2>
                <p>
                  We are building a high-quality platform for an engaged running community based on
                  a common understanding and trust.
                </p>
              </div>
            </div>
          </div>
          <div className={css.headingContainerThree}>
            <div className={css.heading}>
              <FormattedMessage id="AboutPage.headingThree" />
            </div>
            <div className={css.subHeading}>
              <FormattedMessage id="AboutPage.subHeadingThree" />
            </div>
          </div>
          <div className={css.content}>
            <div className={css.steps}>
              <div className={css.step}>
                <p>
                  <strong>SHARE YOUR RUN GmbH</strong><br />
                  Albisriederstrasse 334<br />
                  8047 Zürich<br />
                  Switzerland<br />
                  <ExternalLink href="mailto:hello@shareyourrun.com">hello@shareyourrun.com</ExternalLink>
                </p>
              </div>
              <div className={css.step}>
                <p>
                  <strong>Commercial registration (UID)</strong><br />
                  CHE-421.948.970<br />
                  <br />
                  <strong>VAT number</strong><br />
                  CHE-421.948.970 MWST<br />
                  <br />
                  <strong>Represented by</strong><br />
                  Markus Roth and<br />
                  Christian Kreienbühl
                </p>
              </div>
            </div>
          </div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSideNavigation>
    </Page>
  );
};

const { bool } = PropTypes;

AboutPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  return {
    scrollingDisabled: isScrollingDisabled(state),
  };
};

const AboutPage = compose(
  connect(mapStateToProps),
  injectIntl
)(AboutPageComponent);

export default AboutPage;
