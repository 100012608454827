import React from 'react';
import { Field } from 'react-final-form';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';


import css from './FieldGuestInput.module.css';
import { ValidationError } from '..';

const FieldGuestInputComponent = ({ id, label, input, meta, className, ...rest }) => {
  const increment = () => input.onChange(Number(input.value) + 1);
  const decrement = () => input.onChange(Number(input.value) - 1);

  return (
    <div className={className}>
      <div className={css.guestCountInputWrapper}>
        <label htmlFor={id}>{label}</label>
        <button
          type="button"
          className={css.guestCountDecrement}
          onClick={decrement}
          disabled={input.value === Number(rest.min) || rest.disabled}
        >
          <span>-</span>
        </button>
        <div className={classNames(css.guestCountNumber, { [css.disabled]: rest.disabled })}>
          {input.value}
        </div>
        <button
          type="button"
          className={css.guestCountIncrement}
          onClick={increment}
          disabled={input.value >= Number(rest.max) || rest.disabled}
        >
          <span>+</span>
        </button>
      </div>
      <div className={css.guestCountInputInfoWrapper}>
        <FormattedMessage id="BookingTimeForm.seatsInfo" />
      </div>
      <ValidationError fieldMeta={meta} />
    </div>
  );
};

const FieldGuestInput = props => {
  return <Field defaultValue={1} component={FieldGuestInputComponent} {...props} />;
};

export default FieldGuestInput;
