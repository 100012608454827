import React from 'react';
import { array, shape, string } from 'prop-types';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';

import css from './ListingPage.module.css';

const SectionrunDuration = props => {
  const { publicData, options } = props;

  const runDuration = publicData.runDuration;
  const runDurationOption = options.find(option => option.key === runDuration);

  return runDurationOption ? (
    <div className={css.sectionrunDuration}>
      <h2 className={css.runDurationTitle}>
        <FormattedMessage id="ListingPage.runDurationTitle" />
      </h2>
      <p>{runDurationOption.label}</p>
    </div>
  ) : null;
};

SectionrunDuration.propTypes = {
  options: array.isRequired,
  publicData: shape({
    runDuration: string,
  }).isRequired,
};

export default SectionrunDuration;
