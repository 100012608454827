import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import css from './IconEnquiry.module.css';

const IconEnquiry = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg
      className={classes}
      width="64"
      height="64"
      viewBox="0 0 64 64"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect height="44" fill="#007db8" width="62" x="1" y="16" />
      <polygon points="32 36 1 60 63 60 32 36" fill="#0099e2" />
      <path d="M1,16,30.648,38.953a2.206,2.206,0,0,0,2.7,0L63,16Z" fill="#33beff" />
      <polygon
        points="19 11.968 24.956 16.077 24.956 22.931 27.997 17.991 34.978 23.005 45 9 19 11.968"
        fill="#a9abb1"
      />
      <polygon points="19 11.968 24.956 16.077 45 9 19 11.968" fill="#dfe0e2" />
      <polygon points="27.997 17.991 34.978 23.005 45 9 27.997 17.991" fill="#dfe0e2" />
    </svg>
  );
};

IconEnquiry.defaultProps = {
  rootClassName: null,
  className: null,
};

IconEnquiry.propTypes = {
  rootClassName: string,
  className: string,
};

export default IconEnquiry;
