import React from 'react';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { LINE_ITEM_ADDITIONAL_GUEST, propTypes } from '../../util/types';

import css from './BookingBreakdown.module.css';

const LineItemBasePriceMaybe = props => {
  const { transaction, intl } = props;

  const additionalGuestLineItem = transaction.attributes.lineItems.find(
    item => item.code === LINE_ITEM_ADDITIONAL_GUEST && !item.reversal
  );

  const quantity = additionalGuestLineItem ? additionalGuestLineItem.quantity.toString() : null;
  const unitPrice = additionalGuestLineItem
    ? formatMoney(intl, additionalGuestLineItem.unitPrice)
    : null;
  const total = additionalGuestLineItem
    ? formatMoney(intl, additionalGuestLineItem.lineTotal)
    : null;

  return quantity && total ? (
    <div className={css.lineItem}>
      <span className={css.itemLabel}>
        <FormattedMessage
          id="BookingBreakdown.additionalGuestUnitQuantity"
          values={{ unitPrice, quantity }}
        />
      </span>
      <span className={css.itemValue}>{total}</span>
    </div>
  ) : null;
};

LineItemBasePriceMaybe.propTypes = {
  transaction: propTypes.transaction.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemBasePriceMaybe;
