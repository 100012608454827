import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconSightseeing.module.css';

const IconSightseeing = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg
      className={classes}
      width="35"
      height="35"
      viewBox="0 0 512 512"
      xmlns="http://www.w3.org/2000/svg"
    >

<rect height="37.877" fill="#0099e2" width="48" x="416" y="121.113"/>
<rect height="18.887" fill="#007db8" width="48" x="416" y="121.113"/>
<path d="M121.11,133.106H159.1a9.006,9.006,0,0,1,9.006,9.006v16.2a0,0,0,0,1,0,0h-56a0,0,0,0,1,0,0v-16.2A9.006,9.006,0,0,1,121.11,133.106Z" fill="#0099e2"/>
<path d="M103.126,157.106H475.631A12.369,12.369,0,0,1,488,169.474V424.737a12.369,12.369,0,0,1-12.369,12.369H103.126a0,0,0,0,1,0,0v-280A0,0,0,0,1,103.126,157.106Z" fill="#33beff"/>
<circle cx="293.106" cy="297.106" r="116" fill="#dfe0e2"/>
<circle cx="279.319" cy="301.701" r="116" fill="#0099e2"/>
<circle cx="293.106" cy="297.106" r="116" fill="#dfe0e2"/>
<circle cx="293.106" cy="297.106" r="77" fill="#a9abb1"/>
<circle cx="293.106" cy="297.106" r="48" fill="#f5f5f8"/>
<path d="M36.369,157.106H112.1a0,0,0,0,1,0,0v280a0,0,0,0,1,0,0H36.369A12.369,12.369,0,0,1,24,424.737V169.474A12.369,12.369,0,0,1,36.369,157.106Z" fill="#0099e2"/>
<circle cx="172.379" cy="193.139" r="16" fill="#ffd36c"/>
<circle cx="293.106" cy="297.106" r="20.08" fill="#a9abb1"/>
<path d="M36.369,437.106H112.1V402.645H24v22.092A12.369,12.369,0,0,0,36.369,437.106Z" fill="#007db8"/>
<path d="M24,169.474v23.445h88.1V157.106H36.369A12.368,12.368,0,0,0,24,169.474Z" fill="#007db8"/>
<rect height="48" fill="#ffd36c" width="96" x="392" y="76"/>
<circle cx="440" cy="100" r="8" fill="#fff"/>

    </svg>
  );
};

IconSightseeing.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

IconSightseeing.propTypes = { rootClassName: string, className: string };

export default IconSightseeing;
