import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconAll.module.css';

const IconAll = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg
      className={classes}
      width="35"
      height="35"
      viewBox="0 0 512 512"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M241.291,312.252c-16.937-9.85-29.163-16.984-31.546-34.919l-24.9,76.076H70.823v82.645H87.3A16.4,16.4,0,0,0,103.563,421.8l3.406-25.843H210.494L255.148,320.5q-4.482-2.74-9.386-5.633Q243.487,313.523,241.291,312.252Z"
        fill="#fcd3c4"
      />
      <path
        d="M426.545,174.484c-3.984-13.579-20.943-2.159-20.943-2.159s-2.531-10.754-10.821-8.74-21.267,23.265-21.267,23.265l-28.5,15.913L313.26,137.574,228.8,129.556,155.347,154.7a9.636,9.636,0,0,0-6.515,9.079l-.24,64.466s-2.931,22.719-2.685,29.713c.44,12.461,6.163,18.807,19.068,21.694,13.811,3.089,12.031-17.278,12.031-17.278s10.635,2.992,12.886-5.237-10.057-29.872-10.057-29.872l1.719-39.566,41.19-11.474s-9.462,59.064-12.169,70.506c-11.023,46.588,7.675,54.25,34.172,69.855,31.715,18.679,50.044,32.888,50.044,32.888L280.032,438.1l-.435,2.44,1.792,1.374c4.083,3.321,12.181,9.11,20.588,10.611a25.294,25.294,0,0,0,4.441.395,27.738,27.738,0,0,0,14.837-4.491l21.6-108.745a11.511,11.511,0,0,0-2.973-10.2l-55.639-58.156,18.163-73.008,23.208,40.1a9.634,9.634,0,0,0,13.033,3.587L389.573,213.6s21.224-8.32,27.224-11.923C427.487,195.253,430.268,187.173,426.545,174.484Z"
        fill="#fcd3c4"
      />
      <path
        d="M301.977,452.528a25.294,25.294,0,0,0,4.441.395,27.738,27.738,0,0,0,14.837-4.491l5.066-25.5L283.9,414.9,280.032,438.1l-.435,2.44,1.792,1.374C285.472,445.238,293.57,451.027,301.977,452.528Z"
        fill="#0099e2"
      />
      <path
        d="M313.26,137.574,228.8,129.556,183.89,144.93l10.87,39.091,27.984-7.795s-6.333,39.535-10.119,60.18l76.026,17.213,13.756-55.3,4.882,8.435,30.758-18.294Z"
        fill="#0099e2"
      />
      <path
        d="M317.307,74.86l-.026-28.039-16.728.021L288.861,73.548l-3.634-6.7A11.855,11.855,0,0,0,274.8,60.643l-.406,0a14.783,14.783,0,0,0-14.351,11.819l-6.392,30.514,1.024,4.872-.18,23.914a17.923,17.923,0,0,0,17.945,18.057h.881a17.925,17.925,0,0,0,17.9-17.756l.157-16.828L312.936,118a4.634,4.634,0,0,0,5.222-4.608l-.039-13.92,9.932-3.955Z"
        fill="#fcd3c4"
      />
      <path
        d="M301.861,48.84,289.054,78.1,283.469,67.8a9.863,9.863,0,0,0-8.8-5.158l-.244,0A12.867,12.867,0,0,0,262,72.876l-7.326,34.976L242.159,94.609a19.687,19.687,0,0,1-5.378-13.5l-.031-24.639a32.355,32.355,0,0,1,32.316-32.395L331.72,24l.018,14.772a10.031,10.031,0,0,1-10.019,10.044Z"
        fill="#484a50"
      />
      <path
        d="M321.825,445.561s-8.226,7.011-19.5,5-22.3-12.456-22.3-12.456l-6.285,35.514L354.918,488l1.979-11.153a18.14,18.14,0,0,0-10.154-19.591Z"
        fill="#484a50"
      />
      <path
        d="M241.291,312.252c-16.937-9.85-29.163-16.984-31.546-34.919l-14.456,44.173,44.41,25.1,15.448-26.1q-4.651-2.843-9.385-5.635Q243.487,313.523,241.291,312.252Z"
        fill="#221b57"
      />
      <path
        d="M96.589,377.652a26.919,26.919,0,0,0,7.5,18.3h27.261l-.074-42.543H104.7l-1.384,2C100.742,359.757,96.589,368.241,96.589,377.652Z"
        fill="#0099e2"
      />
      <path
        d="M98.589,377.653c0-12.835,8.306-24.108,8.306-24.108v-.136H70.823v82.645H87.3A16.4,16.4,0,0,0,103.563,421.8l3.406-25.843S98.589,389.1,98.589,377.653Z"
        fill="#484a50"
      />
      <path
        d="M255.147,320.5q-4.651-2.843-9.385-5.635-2.275-1.341-4.471-2.612c-15.068-8.763-28.631-15.943-31.546-34.919-3.305,6.959-.1,17.284,1.65,22.139,4.272,11.835,14.016,19.656,20.334,29.786A72.344,72.344,0,0,1,239.7,346.6Z"
        fill="#18133e"
      />
      <path
        d="M210.575,246.732c-11.023,46.588,7.675,54.25,34.172,69.855,8.189,4.823,15.477,9.344,21.795,13.4l39.375-36.006-21.673-22.653,4.407-17.712-76.026-17.213C211.783,240.993,211.067,244.652,210.575,246.732Z"
        fill="#221b57"
      />
    </svg>
  );
};

IconAll.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

IconAll.propTypes = { rootClassName: string, className: string };

export default IconAll;
