import React from 'react';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { required } from '../../util/validators';
import { Form, Button, FieldSelect } from '../../components';

// Create this file using EditListingFeaturesForm.module.css
// as a template.
import css from './EditListingDetailsForm.module.css';

export const EditListingDetailsFormComponent = props => (
  <FinalForm
    {...props}
    render={fieldRenderProps => {
      const {
        className,
        disabled,
        handleSubmit,
        name,
        intl,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        updateError,
        updateInProgress,
        runDurationOptions,
        runDistanceOptions,
        runMaxPaxOptions,
      } = fieldRenderProps;

      const runDurationPlaceholder = intl.formatMessage({
        id: 'EditListingDetailsForm.runDurationPlaceholder',
      });
      const runDistancePlaceholder = intl.formatMessage({
        id: 'EditListingDetailsForm.runDistancePlaceholder',
      });
      const runMaxPaxPlaceholder = intl.formatMessage({
        id: 'EditListingDetailsForm.runMaxPaxPlaceholder',
      });

      const errorMessage = updateError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingDetailsForm.updateFailed" />
        </p>
      ) : null;

      const runDurationMessage = intl.formatMessage({
        id: 'EditListingDetailsForm.runDuration',
      });
      const runDistanceMessage = intl.formatMessage({
        id: 'EditListingDetailsForm.runDistance',
      });
      const runMaxPaxMessage = intl.formatMessage({
        id: 'EditListingDetailsForm.runMaxPax',
      });
      const runDurationRequired = required(
        intl.formatMessage({
          id: 'EditListingDetailsForm.runDurationRequired',
        })
      );
      const runDistanceRequired = required(
        intl.formatMessage({
          id: 'EditListingDetailsForm.runDistanceRequired',
        })
      );
      const runMaxPaxRequired = required(
        intl.formatMessage({
          id: 'EditListingDetailsForm.runMaxPaxRequired',
        })
      );

      const classes = classNames(css.root, className);
      const submitReady = updated && pristine;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {errorMessage}

          <FieldSelect
            className={css.details}
            name="runDuration"
            id="runDuration"
            validate={runDurationRequired}
            label={runDurationMessage}
          >
            <option value="">{runDurationPlaceholder}</option>
            <option key="tailormadeDur" value="tailormadeDur">
              tailormade (can be agreed during the booking process)
            </option>
            <option key="30minorless" value="30minorless">
              30 min or less
            </option>
            <option key="about45min" value="about45min">
              45 min
            </option>
            <option key="about1h" value="about1h">
              1 h
            </option>
            <option key="about1_5h" value="about1_5h">
              1.5 h
            </option>
            <option key="about2h" value="about2h">
              2 h
            </option>
            <option key="about2_5h" value="about2_5h">
              2.5 h
            </option>
            <option key="about3h" value="about3h">
              3 h
            </option>
            <option key="about3_5h" value="about3_5h">
              3.5 h
            </option>
            <option key="about4h" value="about4h">
              4 h
            </option>
            <option key="about4_5h" value="about4_5h">
              4.5 h
            </option>
            <option key="about5h" value="about5h">
              5 h
            </option>
            <option key="about5_5h" value="about5_5h">
              5.5 h
            </option>
            <option key="6hormore" value="6hormore">
              6 h or more
            </option>
            <option key="morethan1day" value="morethan1day">
              more than 1 day
            </option>
          </FieldSelect>

          <FieldSelect
            className={css.details}
            name="runDistance"
            id="runDistance"
            validate={runDistanceRequired}
            label={runDistanceMessage}
          >
            <option value="">{runDistancePlaceholder}</option>
            <option key="tailormadeDist" value="tailormadeDist">
              tailormade (can be agreed during the booking process)
            </option>
            <option key="3kmorless" value="3kmorless">
              3 km or less
            </option>
            <option key="4or5km" value="4or5km">
              4 or 5 km
            </option>
            <option key="6or7km" value="6or7km">
              6 or 7 km
            </option>
            <option key="8or9km" value="8or9km">
              8 or 9 km
            </option>
            <option key="10or11km" value="10or11km">
              10 or 11 km
            </option>
            <option key="12or13km" value="12or13km">
              12 or 13 km
            </option>
            <option key="14or15km" value="14or15km">
              14 or 15 km
            </option>
            <option key="16or17km" value="16or17km">
              16 or 17 km
            </option>
            <option key="18or17km" value="18or19km">
              18 or 19 km
            </option>
            <option key="about20to29km" value="about20to29km">
              20 to 29 km
            </option>
            <option key="halfmarathon" value="halfmarathon">
              Half marathon (21.0975 km)
            </option>
            <option key="about30to39km" value="about30to39km">
              30 to 39 km
            </option>
            <option key="40kmormore" value="40kmormore">
              40 km or more
            </option>
            <option key="marathon" value="marathon">
              Marathon (42.195 km)
            </option>
          </FieldSelect>

          <FieldSelect
            className={css.details}
            name="runMaxPax"
            id="runMaxPax"
            validate={runMaxPaxRequired}
            label={runMaxPaxMessage}
          >
            <option value="">{runMaxPaxPlaceholder}</option>
            <option key="1" value="1">
              1
            </option>
            <option key="2" value="2">
              2
            </option>
            <option key="3" value="3">
              3
            </option>
            <option key="4" value="4">
              4
            </option>
            <option key="5" value="5">
              5
            </option>
            <option key="6" value="6">
              6
            </option>
            <option key="7" value="7">
              7
            </option>
            <option key="8" value="8">
              8
            </option>
            <option key="9" value="9">
              9
            </option>
            <option key="10" value="10">
              10
            </option>
          </FieldSelect>

          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button>
        </Form>
      );
    }}
  />
);

EditListingDetailsFormComponent.defaultProps = {
  selectedPlace: null,
  updateError: null,
};

EditListingDetailsFormComponent.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  updated: bool.isRequired,
  updateError: propTypes.error,
  updateInProgress: bool.isRequired,
  runDurationOptions: arrayOf(
    shape({
      key: string.isRequired,
      label: string.isRequired,
    })
  ).isRequired,
  runDistanceOptions: arrayOf(
    shape({
      key: string.isRequired,
      label: string.isRequired,
    })
  ).isRequired,
};

export default compose(injectIntl)(EditListingDetailsFormComponent);
