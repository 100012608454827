import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';

import { NamedLink } from '../../components';

import css from './SectionLocations.module.css';

import oneImage from './images/one.jpg';
import twoImage from './images/two.jpg';
import threeImage from './images/three.jpg';
import fourImage from './images/four.jpg';
import fiveImage from './images/five.jpg';
import sixImage from './images/six.jpg';

class LocationImage extends Component {
  render() {
    const { alt, ...rest } = this.props;
    return <img alt={alt} {...rest} />;
  }
}
const LazyImage = lazyLoadWithDimensions(LocationImage);

const locationLink = (name, image, listingId) => {
  const nameText = <span className={css.locationName}>{name}</span>;
  return (
    <NamedLink name="ListingPageCanonical" params={{ id: listingId }} className={css.location}>
      <div className={css.imageWrapper}>
        <div className={css.aspectWrapper}>
          <LazyImage src={image} alt={name} className={css.locationImage} />
        </div>
      </div>
      <div className={css.linkText}>
        <FormattedMessage
          id="SectionLocations.listingsInLocation"
          values={{ location: nameText }}
        />
      </div>
    </NamedLink>
  );
};

const SectionLocations = props => {
  const { rootClassName, className } = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div className={css.title}>
        <FormattedMessage id="SectionLocations.title" />
      </div>
      <div className={css.locations}>
        {locationLink(
          'Zürich Sightseeing Run with Barbara (€40)',
          oneImage,
          '61373ba4-8037-43b7-bc45-fad8364c6916'
        )}
        {locationLink(
          'Run with a marathon champion in Playitas - Fuerteventura (€100)',
          twoImage,
          '637e56ab-9943-4804-83bb-c512cc48e7ab'
        )}
        {locationLink(
          'Coaching Run - Marathon oder Ultra (€80)',
          threeImage,
          '60a7ac7f-5de6-4f08-8605-6783ccf7be87'
        )}
      </div>
      <div className={css.locations}>
        {locationLink(
          'Thun - Sightseeing Run (€30)',
          fourImage,
          '609ffa46-6006-41e0-8ad7-19babf70ac0b'
        )}
        {locationLink(
          'The route that led me to the Rio Olympics - in your pace (€60)',
          fiveImage,
          '60959936-7220-4c27-a2df-c2bc437b8e27'
        )}
        {locationLink(
          'Stockhorn - Stairway to Heaven (€200)',
          sixImage,
          '60ae500f-d356-41c0-a593-08d6e5f1245d'
        )}
      </div>
    </div>
  );
};

SectionLocations.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

SectionLocations.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionLocations;
