import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconSocialMediaStrava.module.css';

const IconSocialMediaStrava = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg
      className={classes}
      width="20"
      height="20"
      viewBox="0 0 64 64"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M41.03 47.852l-5.572-10.976h-8.172L41.03 64l13.736-27.124h-8.18" />
      <path d="M27.898 21.944l7.564 14.928h11.124L27.898 0 9.234 36.876H20.35" />
    </svg>
  );
};

IconSocialMediaStrava.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

IconSocialMediaStrava.propTypes = { rootClassName: string, className: string };

export default IconSocialMediaStrava;
