import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconCoaching.module.css';

const IconCoaching = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg
      className={classes}
      width="35"
      height="35"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path fill="#221b57" d="M7,39a4,4,0,0,1,0-8l.06,2A2,2,0,1,0,9,35a1.83,1.83,0,0,0-.17-.78l1.82-.83A3.85,3.85,0,0,1,11,35,4,4,0,0,1,7,39Z"/>
      <path fill="#0099e2" d="M23,12v3h6V12H45v6L27,24A12,12,0,1,1,14,12Z"/>
      <circle fill="#33beff" cx="15" cy="24" r="7"/>
    </svg>
  );
};

IconCoaching.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

IconCoaching.propTypes = { rootClassName: string, className: string };

export default IconCoaching;
